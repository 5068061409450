/*
 * Layout Store
 * */
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLayoutStore = defineStore('layout', () => {
  const dark = ref((localStorage['eng-dark-mode'] || 'false') === 'true');
  const print = ref(false);
  const progressbar = ref(false);
  const refresh = ref(0);

  // Set dark mode true or false
  function setDark(darkValue) {
    localStorage['eng-dark-mode'] = darkValue;
    dark.value = darkValue;
  }
  // Enable / disable print layout
  function setPrint(printValue) {
    console.log(`Layout: setPrint: ${printValue}`);
    print.value = printValue;
  }
  // Can pass true (show auto), false (hide), or number (show with %%)
  function setProgress(progressValue = false) {
    progressbar.value = progressValue;
  }
  // Trigger refresh in all components that are watching layout.state.refresh
  function setRefresh() {
    refresh.value = Date.now();
  }

  return {
    dark,
    print,
    progressbar,
    refresh,
    setDark,
    setPrint,
    setProgress,
    setRefresh,
  };
});
