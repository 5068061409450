<template>
  <div class="flex flex-col">
    <!-- header -->
    <header class="z-10 px-6 pt-4 pb-2 sticky bg-white" style="top: calc(4rem + 1px)">
      <div class="flex items-center justify-between md:space-x-5">
        <div class="flex items-center space-x-5">
          <div class="flex-shrink-0">
            <div class="relative bg-violet-600/70 rounded-xl p-1">
              <mdicon name="play-box-outline" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
            </div>
          </div>
          <div class="">
            <h1 class="text-2xl font-bold text-gray-900">
              {{ specData?.name }}
            </h1>
            <div class="">{{ jobTypeTitle }} Job</div>
          </div>
        </div>

        <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <button type="button" class="mr-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onExecuteJob">
            <PlayIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-green-700" aria-hidden="true" />
            Execute Job
          </button>
          <button
            type="button"
            class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="onSaveJob"
          >
            <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Save
          </button>
        </div>
      </div>

      <div class="">
        <div v-if="advanced" class="hidden lg:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <div
                v-for="tab in tabs"
                :key="tab.name"
                :class="[
                  tab.name === currentTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-3 px-1 text-sm font-medium cursor-pointer',
                ]"
                :aria-current="tab.name === currentTab ? 'page' : undefined"
                @click="onSelectTab(tab.name)"
              >
                <component :is="tab.icon" :class="[tab.name === currentTab ? 'text-indigo-500' : 'text-indigo-300 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                <span>{{ tab.name }}</span>
              </div>
            </nav>
          </div>
        </div>
        <div v-else class="mt-4 border-b"></div>

        <!--<div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
          </select>
        </div>
        <div class="hidden sm:block">
          <nav class="flex space-x-4" aria-label="Tabs">
            <button
              v-for="tab in tabs"
              :key="tab.name"
              :href="tab.href"
              :class="[tab.name === currentTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']"
              @click="onSelectTab(tab.name)"
            >
              {{ tab.name }}
            </button>
          </nav>
        </div>-->
      </div>
    </header>

    <div class="flex-auto z-0 mx-6 my-4">
      <div v-if="isTabVisible('Settings')" :class="isTabVisible('Settings') ? '' : 'lg:hidden'" class="">
        <JobAnalysisSpecSettingsEditor :spec-data="specData" @update:spec-prop="onSpecPropUpdate" @update:spec-script-step-prop="onSpecScriptStepPropUpdate" v-model:advanced="advanced"></JobAnalysisSpecSettingsEditor>
      </div>
      <div v-if="isTabVisible('Advanced') && advanced" :class="isTabVisible('Advanced') ? '' : 'lg:hidden'">
        <!--<JobSpecParameters :spec-data="specData" @update:spec-prop="onSpecPropUpdate"></JobSpecParameters>-->
        <SpecScriptEditorRestricted
          :media="specData?.embeddedScenario?.media"
          :steps="specData?.embeddedScenario?.script?.steps"
          @step-form-active="onScriptStepFormActive"
          @update:prop-value="onScriptStepUpdatePropValue"
        ></SpecScriptEditorRestricted>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { PlayIcon, CheckIcon, ChevronDoubleRightIcon, HashtagIcon } from '@heroicons/vue/20/solid';
  import { watch, ref, reactive, computed } from 'vue';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useBreakpoint } from '@/hooks/useBreakpoint';
  import { useToast } from 'vue-toastification';
  import { nanoid } from 'nanoid';
  import JobAnalysisSpecSettingsEditor from '@/components/Jobs/Edit/JobAnalysisSpecSettingsEditor.vue';
  import SpecScriptEditorRestricted from '@/components/Tests/SpecScriptEditorRestricted.vue';
  import { getStepDef } from '@/spec/ActionDefs.js';
  import { JOB_TYPE_INFO } from '@/const';

  const props = defineProps({
    spec: {
      type: Object,
      default() {
        return {};
      },
    },
  });
  const emit = defineEmits(['save', 'execute', 'update']);

  const layout = useLayoutStore();
  const { breakpoints } = useBreakpoint();

  // Navigation
  const tabs = [
    { name: 'Settings', icon: PlayIcon },
    { name: 'Advanced', icon: CheckIcon },
  ];
  const currentTab = ref(tabs[0].name);

  function onSelectTab(tabName) {
    currentTab.value = tabName;
  }

  function isTabVisible(tabName) {
    if (layout.print || ['xs', 'sm', 'md'].includes(breakpoints.is)) {
      return true;
    }
    return currentTab.value === tabName;
  }

  const advanced = ref(false);

  const jobTypeTitle = JOB_TYPE_INFO[props.spec.type]?.title || 'UNKNOWN';
  const processStepType = JOB_TYPE_INFO[props.spec.type]?.processStep || 'UNKNOWN'; //'processTranscript';
  const processStepDef = getStepDef(processStepType);
  const scenarioMedia = JOB_TYPE_INFO[props.spec.type]?.scenarioMedia || 'UNKNOWN';

  // Make a full copy of the spec, and make it reactive
  const specData = reactive(JSON.parse(JSON.stringify(props.spec)));

  normalizeJobSpec();

  // TODO Support nested, i.e. specData.voice.ANI
  function onSpecPropUpdate(prop, value) {
    //console.log(`onSpecPropUpdate: ${prop} - ${value}`);
    specData[prop] = value;
    // Emit update with the whole spec ???
    emit('update', specData);
  }

  function onSpecScriptStepPropUpdate(idx, prop, value) {
    specData.embeddedScenario.script.steps[idx][prop] = value;
    // Emit update with the whole spec ???
    emit('update', specData);
  }

  function normalizeJobSpec() {
    // TODO Move to function jobsStore.Normalize spec, i.e. add empty steps, etc
    if (!('id' in specData) || !specData.id) {
      specData.id = nanoid(10);
    }
    // ???
    if (!('type' in specData)) {
      specData.type = 'analyzeTranscripts';
    }
    // Embedded Scenario
    if (!('embeddedScenario' in specData)) {
      specData.embeddedScenario = {
        id: nanoid(10),
        name: 'Scenario',
        media: scenarioMedia,
        script: {
          type: 'sequence',
          version: 1,
          steps: [],
        },
      };
      // Add all steps - enable all types of analysis by default
      [processStepType, 'analyzeSummarize', 'analyzeSentiment', 'analyzeProblems', 'analyzeKPI', 'analyzeAgent'].map((x) => {
        const stepDef = getStepDef(x);
        const step = {
          type: x,
        };
        stepDef.props.map((p) => {
          step[p.name] = p.default;
        });
        specData.embeddedScenario.script.steps.push(step);
      });
    }

    // Double-check and fix mandatory "process" steps (processTranscript or processRecording)
    const processStepIdx = stepIdx(processStepType);
    if (processStepIdx === -1) {
      const step = {
        type: processStepType,
      };
      processStepDef.props.map((p) => {
        step[p.name] = p.default;
      });
      // Should be first
      specData.embeddedScenario.script.steps.unshift(step);
    }

    // ???
    emit('update', specData);
  }

  function stepIdx(stepType) {
    return (specData?.embeddedScenario?.script?.steps || []).findIndex((x) => x?.type === stepType);
  }

  function onScriptStepUpdatePropValue(index, prop, value) {
    specData.embeddedScenario.script.steps[index][prop] = value;
  }

  function onScriptStepFormActive(idx, active) {
    specData.embeddedScenario.script.steps[idx]._active = active;
  }

  async function onSaveJob() {
    emit('save', specData);
  }

  function onExecuteJob() {
    console.log(`onExecuteJob`);
    emit('execute', specData);
  }
</script>
