<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="emit('cancel')">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <form class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-slate-200/50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="flex items-center">
                          <div class="flex-shrink-0 mr-4">
                            <div class="relative bg-emerald-600/70 rounded-xl p-1">
                              <mdicon name="account" size="62" class="text-emerald-100" aria-hidden="true"></mdicon>
                            </div>
                          </div>
                          <div v-if="isEditExistingUser" class="space-y-1">
                            <DialogTitle class="text-base font-semibold leading-6 text-gray-900">Edit User</DialogTitle>
                            <p class="text-sm text-gray-500">Update information below</p>
                          </div>
                          <div v-else class="space-y-1">
                            <DialogTitle class="text-base font-semibold leading-6 text-gray-900">Invite User</DialogTitle>
                            <p class="text-sm text-gray-500">Fill in the information below to invite user to your Organization</p>
                          </div>
                        </div>
                        <div class="flex h-7 items-center">
                          <button type="button" class="text-gray-400 hover:text-gray-500" @click="emit('cancel')">
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label for="user-name" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Full Name</label>
                        </div>
                        <div class="sm:col-span-2">
                          <input
                            type="text"
                            name="user-name"
                            id="user-name"
                            v-model="displayName"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label for="user-email" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Email</label>
                        </div>
                        <div class="sm:col-span-2">
                          <input
                            :disabled="isEditExistingUser"
                            type="email"
                            v-model="email"
                            name="user-email"
                            id="user-email"
                            class="block w-full rounded-md border-0 py-1.5 disabled:bg-gray-100 disabled:text-gray-600 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 invalid:[&:not(:placeholder-shown):not(:focus)]:ring-red-500 peer"
                            placeholder="name@domain.com"
                            required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          />
                          <span class="mt-2 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">Please enter a valid email address</span>
                        </div>
                      </div>

                      <!-- Role -->
                      <fieldset class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <legend class="sr-only">Role</legend>
                        <div class="text-sm font-medium leading-6 text-gray-900" aria-hidden="true">Role</div>
                        <div class="space-y-5 sm:col-span-2">
                          <div class="space-y-5 sm:mt-0">
                            <div class="relative flex items-start">
                              <div class="absolute flex h-6 items-center">
                                <input
                                  id="role-admin"
                                  v-model="role"
                                  name="role"
                                  aria-describedby="public-access-description"
                                  value="admin"
                                  type="radio"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  checked=""
                                />
                              </div>
                              <div class="pl-7 text-sm leading-6">
                                <label for="role-admin" class="font-medium text-gray-900">Admin</label>
                                <p class="text-gray-500">Access to all capabilities, and ability to manage Users and Organization settings</p>
                              </div>
                            </div>
                            <div class="relative flex items-start">
                              <div class="absolute flex h-6 items-center">
                                <input
                                  id="role-user"
                                  v-model="role"
                                  name="role"
                                  aria-describedby="restricted-access-description"
                                  value="user"
                                  type="radio"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div class="pl-7 text-sm leading-6">
                                <label for="role-user" class="font-medium text-gray-900">User</label>
                                <p class="text-gray-500">Access to all capabilities</p>
                              </div>
                            </div>
                            <div class="relative flex items-start">
                              <div class="absolute flex h-6 items-center">
                                <input
                                  id="role-agent"
                                  v-model="role"
                                  name="role"
                                  aria-describedby="restricted-access-description"
                                  value="agent"
                                  type="radio"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div class="pl-7 text-sm leading-6">
                                <label for="role-user" class="font-medium text-gray-900">Agent</label>
                                <p class="text-gray-500">Access to Personal Analytics and Coaching</p>
                              </div>
                            </div>
                          </div>
                          <hr class="border-gray-200" />
                        </div>
                      </fieldset>

                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label for="agent-id" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Agent ID</label>
                        </div>
                        <div class="sm:col-span-2">
                          <input
                            type="text"
                            name="agent-id"
                            id="agent-id"
                            v-model="agentId"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div class="flex justify-end space-x-3">
                      <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="emit('cancel')">Cancel</button>
                      <button
                        type="button"
                        class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        @click="onOK"
                      >
                        {{ isEditExistingUser ? 'Save' : 'Invite' }}
                      </button>
                    </div>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  // Sample of form validation: https://github.com/deyemiobaa/tailwindcss_custom_form_validation/blob/main/index.html
  import { ref, computed, watch } from 'vue';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { XMarkIcon } from '@heroicons/vue/24/outline';

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default() {
        return null;
      },
    },
  });

  const emit = defineEmits(['cancel', 'invite', 'save']);

  const isEditExistingUser = computed(() => props.user !== null);

  const displayName = ref('');
  const email = ref('');
  const role = ref('user');
  const agentId = ref('');
  updateUserProps();

  watch(
    () => props.user,
    (newUser) => {
      updateUserProps();
    }
  );

  function updateUserProps() {
    displayName.value = props.user ? props.user.displayName : '';
    email.value = props.user ? props.user.email : '';
    role.value = props.user ? props.user.role : 'user';
    agentId.value = props.user ? props.user.agentId : '';
  }
  function onOK() {
    const event = isEditExistingUser.value ? 'save' : 'invite';
    emit(
      event,
      Object.assign({}, props.user, {
        displayName: displayName.value,
        email: email.value,
        role: role.value,
        agentId: agentId.value,
      })
    );
  }
</script>
