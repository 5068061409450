<template>
  <div class="mx-6 my-6">
    <div class="sm:flex sm:items-center">
      <div class="flex-auto flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-emerald-600/70 rounded-xl p-1">
            <mdicon name="account" size="62" class="text-emerald-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Users</h1>
          <p class="text-sm font-medium text-gray-500">Manage users in your account</p>
        </div>
      </div>

      <div class="flex items-center mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <!--<button
          v-if="showDelete"
          type="button"
          class="mr-2 flex items-center gap-x-1 rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          @click="onDeleteUsers"
        >
          <mdicon name="minus-circle-outline" class="h-6 w-6" aria-hidden="true"></mdicon>
          Delete
        </button>-->
        <button
          type="button"
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onNewUser"
        >
          <mdicon name="account-plus" class="h-6 w-6 mr-2" aria-hidden="true"></mdicon>
          Invite User
        </button>
      </div>
    </div>
    <div class="mt-8">
      <DataTable :value="users" paginator :rows="25" :rowsPerPageOptions="[25, 50, 100]" removableSort tableStyle="min-width: 50rem" class="">
        <!--<Column selectionMode="multiple" headerStyle="width: 2rem"></Column>-->
        <Column field="displayName" header="Name" sortable>
          <template #body="slotProps">
            <div class="flex items-center">
              <mdicon name="account" size="24" class="mr-1 text-slate-50 bg-slate-400/50 rounded-md" aria-hidden="true"></mdicon>
              <div class="font-medium cursor-pointer hover:underline" @click="onEditUser(slotProps.data)">{{ slotProps.data.displayName }}</div>
            </div>
          </template>
        </Column>
        <Column field="email" header="Email" sortable />
        <Column field="agentId" header="Agent ID" sortable />
        <Column field="lastActive" header="Last Active" sortable>
          <template #body="slotProps"> {{ slotProps.data.lastActive ? dayjs(slotProps.data.lastActive).format('MM/DD/YYYY') : '' }} </template>
        </Column>
        <Column field="role" header="Role" sortable>
          <template #body="slotProps">
            <select
              :value="slotProps.data.role"
              @change="onSetRole(slotProps.data.id, $event)"
              class="block w-full rounded-md border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option>admin</option>
              <option>user</option>
              <option>agent</option>
            </select>
          </template>
        </Column>
      </DataTable>
    </div>
    <EditUserDialog :open="editUserOpen" :user="userToEdit" @cancel="editUserOpen = false" @invite="onInviteUser" @save="onSaveUser"></EditUserDialog>
    <ProgressDialog ref="addUserProgress"></ProgressDialog>
  </div>
</template>

<script setup>
  import { useAuthStore } from '@/store/AuthStore';
  import { useCollection } from 'vuefire';
  import { ref, computed } from 'vue';
  import { useToast } from 'vue-toastification';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import EditUserDialog from '@/components/Settings/EditUserDialog.vue';
  import ProgressDialog from '@/components/Dialogs/ProgressDialog.vue';

  import { fbDb } from '@/firebaseApp';
  import { collection } from 'firebase/firestore';
  import dayjs from 'dayjs';
  //import { computed } from 'vue';

  const authStore = useAuthStore();

  const users = useCollection(collection(fbDb, `tenants/${authStore.tenantId}/users`));

  const editUserOpen = ref(false);
  const userToEdit = ref(null);

  // Enable for multi-select and delete, when needed
  //const selectedUsers = ref();
  //const showDelete = computed(() => Array.isArray(selectedUsers.value) && selectedUsers.value.length > 0);

  function onNewUser() {
    userToEdit.value = null;
    editUserOpen.value = true;
  }

  async function onEditUser(user) {
    const userId = user.id;
    userToEdit.value = Object.assign({}, user, { id: userId });
    editUserOpen.value = true;
  }

  const addUserProgress = ref(null);
  const toast = useToast();
  let updatingUser = false;

  async function onInviteUser(userData) {
    if (updatingUser) {
      return;
    }
    updatingUser = true;
    console.log(`On invite user: ${JSON.stringify(userData)}`);
    addUserProgress.value.show({ title: 'Adding User', description: userData.displayName });
    const addResult = await authStore.addUser(userData);
    if (addResult.success) {
      addUserProgress.value.hide();
      editUserOpen.value = false;
      toast.success(`User ${userData.email} added successfully`);
    } else {
      addUserProgress.value.showError({ message: addResult.error });
    }
    updatingUser = false;
  }

  async function onSaveUser(userData) {
    if (updatingUser) {
      return;
    }
    updatingUser = true;
    console.log(`On save user: ${JSON.stringify(userData)}`);
    addUserProgress.value.show({ title: 'Updating User', description: userData.displayName });
    const updateResult = await authStore.updateUser(userData, userToEdit.value);
    if (updateResult.success) {
      addUserProgress.value.hide();
      editUserOpen.value = false;
      toast.success(`User ${userData.email} updated successfully`);
    } else {
      addUserProgress.value.showError({ message: updateResult.error });
    }
    updatingUser = false;
  }

  async function onSetRole(uid, event) {
    const role = event.target.value;
    console.log(`onSetRole: ${uid} : ${role}`);
    const updateResult = await authStore.setUserRole(uid, role);
    if (updateResult.success) {
      toast.success(`Role set successfully`);
    } else {
      toast.error(`Error setting up user role: ${updateResult.error}`);
    }
  }

  async function onDeleteUsers() {
    // TODO
  }
</script>
