<template>
  <div class="h-full w-full relative">
    <div ref="container" class="" style="position: absolute; inset: 0px 0px 0px 0px" />
  </div>
</template>
<script>
  import dbColors from './dbcolors';
  import { dbStdProps } from './dbstdprops';
  import Dygraphs from 'dygraphs';
  //import 'dygraphs/dist/dygraph.css';
  //import log from '../log';
  //let Dygraphs = null;
  export default {
    name: 'DbDygraphs',
    mixins: [dbStdProps],
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Object,
      },
    },
    data() {
      return {
        graph: null,
        needUpdate: false,
        needOptionsUpdate: false,
        optionsChanged: false,
      };
    },
    computed: {
      // Augment passed options with defaults for Dygraphs
      graphOptions: {
        get() {
          // filter out proprietary options
          return Object.assign({}, this.defaultOptions, this.options);
        },
        set() {
          /*noop*/
        },
      },
      defaultOptions: function () {
        return {
          colors: dbColors.getColors(this.dark, this.colorScheme),
          animatedZooms: true,
          labelsDiv: this.$refs.dbdylabels,
          legend: 'follow',
          legendFormatter: this.legendFormatter,
          zoomCallback: this.handleZoom,
          clickCallback: this.handleClick,
          highlightCallback: this.handleHighlight,
          unhighlightCallback: this.handleUnHighlight,
          highlightSeriesBackgroundAlpha: this.dark ? 0.4 : 0.5,
          highlightSeriesBackgroundColor: this.dark ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)',
          axisLineColor: this.dark ? 'rgba(255, 255, 255, 0.3)' : 'rgb(0, 0, 0, 0.3)',
        };
      },
      elementClass: function () {
        return this.dark ? 'db-dygraphs db-dark' : 'db-dygraphs';
      },
    },
    mounted() {
      this.render();
      //this.$nextTick(() => {
      //  this.render();
      //});
      /*
      import('dygraphs').then((module) => {
        console.log('dygraphs: imported');
        Dygraphs = module.default;
        //  note: can use Dygraphs.defaultInteractionModel
        // ???
        // Dygraphs.defaultInteractionModel.dblclick = this.handleDblclick;
      });*/
    },
    watch: {
      _updated: function () {
        console.log('_updated prop changed');
        this.scheduleUpdate();
      },
      data: {
        handler() {
          console.log('data prop changed');
          this.scheduleUpdate();
        },
        deep: true,
      },
      options: {
        handler() {
          this.optionsChanged = true;
          this.scheduleUpdate();
        },
        deep: true,
      },
      dark: function () {
        this.optionsChanged = true;
        this.scheduleUpdate();
      },
      colorScheme: function () {
        this.optionsChanged = true;
        this.scheduleUpdate();
      },
    },
    methods: {
      render() {
        this.graph = new Dygraphs(this.$refs.container, this.getData(), this.graphOptions);
      },
      getDygraphs() {
        return this.graph;
      },
      getData() {
        // pre-process data if needed
        if (!Array.isArray(this.data)) {
          return this.data;
        }
        if (this.data.length <= 0) {
          return this.data;
        }
        if (Array.isArray(this.data[0])) {
          return this.data;
        }
        let idx = 0;
        return this.data.map((x) => [idx++, x]);
      },
      scheduleUpdate() {
        this.needUpdate = true;
        this.$nextTick(() => {
          this.update();
        });
      },
      update() {
        // Prevent multiple re-draws per single data update ( because there are redundant watches )
        if (!this.graph || !this.needUpdate) {
          return;
        }
        this.needUpdate = false;
        console.log('DbDygraphs: updating ...');
        if (this.optionsChanged) {
          this.optionsChanged = false;
          this.graph.updateOptions(Object.assign({}, this.graphOptions, { file: this.getData() }));
        } else {
          this.graph.updateOptions({ file: this.getData() });
        }
      },
      legendFormatter: function (data) {
        if (data.x == null) {
          // This happens when there's no selection and {legend: 'always'} is set.
          return (
            '' +
            data.series
              .map(function (series) {
                return series.dashHTML + ' ' + series.labelHTML;
              })
              .join(' ')
          );
        }
        let html = this.graph.getLabels()[0] + ': ' + data.xHTML;
        data.series.forEach(function (series) {
          if (!series.isVisible) return;
          let labeledData = series.labelHTML + ': ' + series.yHTML;
          if (series.isHighlighted) {
            labeledData = '<b>' + labeledData + '</b>';
          }
          html += '<br>' + series.dashHTML + ' ' + labeledData;
        });
        console.log(`Legend: ${html}`);
        return html;
      },
      handleClick: function (e, x, points) {
        console.log(`handleClick: x:${x}, points:${JSON.stringify(points)}`);
        this.$emit('db-event', {
          type: 'click',
          x: x,
          points: points,
        });
      },
      handleZoom: function (minDate, maxDate, yRanges) {
        console.log('handleZoom: minDate:' + minDate + ', maxDate:' + maxDate + ', yRanges:' + yRanges);
        this.$emit('db-event', {
          type: 'zoom',
          minDate: Math.floor(minDate),
          maxDate: Math.floor(maxDate),
          yRanges: yRanges,
          g: this.graph,
        });
      },
      handleDblclick: function (event, g, context) {
        // Ignore double-click - disable zoom in
        return;
      },
      handleHighlight: function (event, x, points, row, seriesName) {
        //console.log('handleHighlight: x:' + x + ', points:' + points + ', row:' + row + ', seriesName: ' + seriesName);
        this.$emit('db-event', {
          type: 'highlight',
          x: x,
          points: points,
          row: row,
          seriesName: seriesName,
        });
      },
      handleUnHighlight: function (event) {
        //console.log('handleUnHighlight');
        this.$emit('db-event', {
          type: 'unhighlight',
        });
      },
    },
  };
</script>

<style>
  /**
 * Default styles for the dygraphs charting library.
 */
  .dygraph-legend {
    position: absolute;
    font-size: 14px;
    z-index: 10;
    min-width: 250px; /*labelsDivWidth */
    /*
  dygraphs determines these based on the presence of chart labels.
  It might make more sense to create a wrapper div around the chart proper.
    top: 0px;
    right: 2px;
  */
    background: white;
    line-height: normal;
    text-align: left;
    overflow: hidden;
    color: black;
  }

  .dygraph-legend[dir='rtl'] {
    text-align: right;
  }

  /* styles for a solid line in the legend */
  .dygraph-legend-line {
    display: inline-block;
    position: relative;
    bottom: 0.5ex;
    padding-left: 1em;
    height: 1px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    /* border-bottom-color is set based on the series color */
  }

  /* styles for a dashed line in the legend, e.g. when strokePattern is set */
  .dygraph-legend-dash {
    display: inline-block;
    position: relative;
    bottom: 0.5ex;
    height: 1px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    /* border-bottom-color is set based on the series color */
    /* margin-right is set based on the stroke pattern */
    /* padding-left is set based on the stroke pattern */
  }

  .dygraph-roller {
    position: absolute;
    z-index: 10;
  }

  /* This class is shared by all annotations, including those with icons */
  .dygraph-annotation {
    position: absolute;
    z-index: 10;
    overflow: hidden;
  }

  /* This class only applies to annotations without icons */
  /* Old class name: .dygraphDefaultAnnotation */
  .dygraph-default-annotation {
    border: 1px solid black;
    background-color: white;
    text-align: center;
  }

  /*
  .dygraph-axis-label {
    z-index: 10;
    line-height: normal;
    overflow: hidden;
    color: black;
  }
  */

  .dygraph-axis-label-x {
  }

  .dygraph-axis-label-y {
  }

  .dygraph-axis-label-y2 {
  }

  .dygraph-title {
    font-weight: bold;
    z-index: 10;
    text-align: center;
    /* font-size: based on titleHeight option */
  }

  .dygraph-xlabel {
    text-align: center;
    /* font-size: based on xLabelHeight option */
  }

  /* For y-axis label */
  .dygraph-label-rotate-left {
    text-align: center;
    /* See http://caniuse.com/#feat=transforms2d */
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }

  /* For y2-axis label */
  .dygraph-label-rotate-right {
    text-align: center;
    /* See http://caniuse.com/#feat=transforms2d */
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
</style>
