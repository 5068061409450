<template>
  <div ref="chart" class="flex items-center justify-center" :data-percent="percent">
    <div class="absolute">
      <slot> {{ showPercents ? `${percent}%` : value }} </slot>
    </div>
  </div>
</template>
<script>
  /* DbEasyPie: Easy-Pie-Chart based on https://github.com/rendro/easy-pie-chart */
  import EasyPieChart from 'easy-pie-chart';
  export default {
    name: 'DbEasyPie',
    props: {
      value: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 100,
      },
      showPercents: { type: Boolean, default: false },
      barColor: {
        type: String,
        default: '#ef1e25',
      },
      trackColor: {
        type: String,
        default: null,
      },
      scaleColor: { type: String, default: '#dfe0e0' },
      scaleLength: { type: Number, default: 10 },
      lineCap: { type: String, default: 'round' },
      lineWidth: { type: Number, default: 5 },
      size: { type: Number, default: 0 },
      rotate: { type: Number, default: 0 },
      duration: { type: Number, default: 1000 },
      animated: { type: Boolean, default: true },
      dark: { type: Boolean, default: false },
      percentRanges: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        pieChart: undefined,
        pieSize: 150,
      };
    },
    computed: {
      percent() {
        return ((this.value / this.max) * 100).toFixed(1);
      },
      hasRanges() {
        return Array.isArray(this.percentRanges) && this.percentRanges.length > 0;
      },
      epTrackColor() {
        if (this.trackColor) {
          return this.trackColor;
        }
        return this.dark ? '#37474f' : '#f2f2f2';
      },
    },
    watch: {
      value(val) {
        this.update(this.percent);
      },
      max(val) {
        this.update(this.percent);
      },
      duration(val) {
        this.pieChart.options.animate.duration = val;
        this.update(this.percent);
      },
      animated(val) {
        this.pieChart.options.animate.enabled = val;
        this.update(this.percent);
      },
      dark() {
        this.$nextTick(() => {
          this.render();
        });
      },
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
      this.render();
    },
    beforeUnmount: function () {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      update(val) {
        this.pieChart.update(val);
      },
      getOptions() {
        // ???
        return;
      },
      initSize() {
        if (this.size !== 0) {
          this.pieSize = this.size;
        } else if (this.$refs.chart) {
          let cH = this.$refs.chart.clientHeight;
          let cW = this.$refs.chart.clientWidth;
          this.pieSize = cH > cW ? cW : cH;
        }
      },
      handleResize(/*event*/) {
        this.$nextTick(() => {
          this.render();
        });
      },
      getBarColor(percent) {
        if (!this.hasRanges) {
          return this.barColor;
        }
        let c = this.percentRanges[0]?.color || this.barColor;
        for (let range of this.percentRanges) {
          //console.log(`Check range: ${JSON.stringify(range)} vs ${percent}`);
          if (range.value > percent) {
            //console.log(`Return: ${c} for ${percent}`);
            return c;
          } else {
            c = range.color;
          }
        }
        //console.log(`Return: ${c} for ${percent}`);
        return c;
      },
      render() {
        if (!this.$refs.chart) {
          return;
        }
        this.initSize();
        if (this.pieChart) {
          // Remove canvas element
          this.$refs.chart.removeChild(this.$refs.chart.lastChild);
        }
        this.pieChart = new EasyPieChart(this.$refs.chart, {
          barColor: this.getBarColor,
          trackColor: this.epTrackColor,
          scaleColor: this.scaleColor,
          scaleLength: this.scaleLength,
          lineCap: this.lineCap,
          lineWidth: this.lineWidth,
          size: this.pieSize,
          rotate: this.rotate,
          animate: {
            duration: this.duration,
            enabled: this.animated,
          },
        });
      },
    },
  };
</script>
