/*
 * Conversation Search Store
 * */
import { ref, markRaw } from 'vue';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useAnalyticsStore } from '@/store/AnalyticsStore';
import { useDateRangeStore } from '@/store/DaterangeStore';
import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';
import { useToast } from 'vue-toastification';
import { pathOr } from 'ramda';

export const useAgentsInsightsStore = defineStore('agentsInsights', () => {
  const authStore = useAuthStore();
  const tenantId = computed(() => authStore.tenantId);

  const analyticsStore = useAnalyticsStore();
  const dateRangeStore = useDateRangeStore();

  const toast = useToast();

  // Results
  const conversationsCount = ref(0);
  const minAvgScore = ref(0);
  const maxAvgScore = ref(0);
  const scoreRange = computed(() => maxAvgScore.value - minAvgScore.value);
  const scoreRange10pct = computed(() => Math.floor(scoreRange.value * 0.1));
  const scoreCutoffBottom = computed(() => minAvgScore.value + scoreRange10pct.value);
  const scoreCutoffTop = computed(() => maxAvgScore.value - scoreRange10pct.value);

  const agents = ref([]);
  const agentsTopPerformers = ref([]);
  const agentsBottomPerformers = ref([]);
  const histogramData = ref([]);
  const histogramHighlightRanges = ref({});

  // TODO Revisit this. Not efficient.
  // All known agents
  const allKnownAgents = ref(null);
  async function getAllKnownAgents() {
    if (allKnownAgents.value !== null) {
      return allKnownAgents.value;
    }
    console.log(`Searching for all known agents ...`);
    const agentsQuery = await queryAgents({ from: 'P1Y', to: 'now', interval: '1M' });
    if (agentsQuery.success) {
      const allAgents = [];
      const agentsBuckets = agentsQuery?.result?.aggregations?.agent?.buckets || null;
      if (agentsBuckets) {
        for (let i = 0; i < agentsBuckets.length; i++) {
          const bucket = agentsBuckets[i];
          allAgents.push({
            agentId: bucket.key,
            agentName: pathOr('', ['AgentName', 'buckets', 0, 'key'], bucket),
            conversationsCount: bucket?.doc_count || 0,
          });
        }
        allKnownAgents.value = allAgents;
      }
    }
    console.log(`All known agents: found ${Array.isArray(allKnownAgents.value) ? allKnownAgents.value.length : 0} agents.`);
    return allKnownAgents.value;
  }

  async function analyzeAgents() {
    const agentsQuery = await queryAgents();
    if (agentsQuery.success) {
      processQueryResult(agentsQuery.result);
    }
  }

  async function queryAgents(timeInterval = null) {
    const q = new AnalyticsQuery();

    const queryTimeInterval = timeInterval ? timeInterval : dateRangeStore.timeInterval;
    q.timeInterval.from = queryTimeInterval?.from || 'StartOfDay';
    q.timeInterval.to = queryTimeInterval?.to || 'now';
    q.timeInterval.interval = dateRangeStore.timeInterval?.interval || '1D'; // '1D'

    q.groupings = [
      {
        name: 'agent',
        title: 'Agent',
        type: 'terms',
        field: 'AgentId',
        groupings: [
          {
            name: 'stats',
            title: 'Stats',
            type: 'stats',
            field: 'score',
          },
          { name: 'AgentName', type: 'terms', size: 1, field: 'AgentName' },
        ],
      },
    ];

    q.request = AnalyticsQuery.prepareQuery({
      timeInterval: q.timeInterval,
      groupings: q.groupings,
      indexPrefix: 'conv',
      timestampField: 'datetime',
    });

    await analyticsStore.executeAnalyticsQuery(q);
    return q;
  }

  function processQueryResult(queryResult) {
    conversationsCount.value = 0;
    minAvgScore.value = 0;
    maxAvgScore.value = 0;
    agents.value = [];
    agentsTopPerformers.value = [];
    agentsBottomPerformers.value = [];
    histogramData.value = [];
    histogramHighlightRanges.value = {};

    let currMinAvgScore = 1000;
    let currMaxAvgScore = -1000;
    let currConversations = 0;
    const agentsData = [];

    const agentsBuckets = queryResult?.aggregations?.agent?.buckets || null;
    if (!agentsBuckets) {
      return false;
    }

    for (let i = 0; i < agentsBuckets.length; i++) {
      const bucket = agentsBuckets[i];
      const scoreAvg = bucket?.stats?.avg || 0;
      if (scoreAvg < currMinAvgScore) {
        currMinAvgScore = scoreAvg;
      }
      if (scoreAvg > currMaxAvgScore) {
        currMaxAvgScore = scoreAvg;
      }
      currConversations += bucket?.doc_count || 0;
      agentsData.push({
        agentId: bucket.key,
        agentName: pathOr('', ['AgentName', 'buckets', 0, 'key'], bucket),
        conversationsCount: bucket?.doc_count || 0,
        scoreMin: bucket?.stats?.min || 0,
        scoreMax: bucket?.stats?.max || 0,
        scoreAvg: parseFloat((bucket?.stats?.avg || 0).toFixed(2)),
        scoreSum: bucket?.stats?.sum || 0,
      });
    }

    conversationsCount.value = currConversations;
    minAvgScore.value = parseFloat(currMinAvgScore.toFixed(2));
    maxAvgScore.value = parseFloat(currMaxAvgScore.toFixed(2));
    agents.value = agentsData;
    agentsTopPerformers.value = agentsData.filter((x) => x.scoreAvg >= scoreCutoffTop.value);
    agentsBottomPerformers.value = agentsData.filter((x) => x.scoreAvg <= scoreCutoffBottom.value);

    calculateHistogram();
  }

  function calculateHistogram() {
    // Histogram
    const scoreRangeBuckets = scoreRange.value > 100 ? 100 : Math.floor(scoreRange.value) + 1;
    const rs = scoreRange.value / scoreRangeBuckets;
    const scoreRangeStep = rs < 1 ? 1 : Math.round(rs);
    const lowerBucket = Math.floor(minAvgScore.value / scoreRangeStep) * scoreRangeStep - scoreRangeStep;
    const upperBucket = Math.floor(maxAvgScore.value / scoreRangeStep) * scoreRangeStep + scoreRangeStep;
    const buckets = [];
    const counts = [];
    const bucketsIdx = {};
    let cntr = 0;
    for (let i = lowerBucket; i <= upperBucket; i += scoreRangeStep) {
      buckets.push(i);
      counts.push(0);
      bucketsIdx[i] = cntr++;
    }
    agents.value.map((a) => {
      const ab = Math.floor(a.scoreAvg / scoreRangeStep) * scoreRangeStep;
      const abIdx = bucketsIdx[ab];
      counts[abIdx] += 1;
    });
    const hData = [];
    for (let i = 0; i < buckets.length; i++) {
      hData.push([buckets[i], counts[i]]);
    }
    histogramHighlightRanges.value = {
      low: {
        start: lowerBucket,
        end: Math.floor((lowerBucket + scoreRange10pct.value) / scoreRangeStep) * scoreRangeStep + scoreRangeStep,
      },
      high: {
        start: Math.floor((upperBucket - scoreRange10pct.value) / scoreRangeStep) * scoreRangeStep - scoreRangeStep,
        end: upperBucket,
      },
    };
    histogramData.value = hData;
  }

  return {
    analyzeAgents,
    getAllKnownAgents,
    conversationsCount,
    minAvgScore,
    maxAvgScore,
    scoreRange,
    scoreRange10pct,
    scoreCutoffBottom,
    scoreCutoffTop,
    agents,
    agentsTopPerformers,
    agentsBottomPerformers,
    allKnownAgents,
    histogramData,
    histogramHighlightRanges,
  };
});
