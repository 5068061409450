<template>
  <div v-if="!isHidden">
    <label :for="propDef?.name" v-if="showLabel" class="block text-sm font-medium leading-6 text-gray-900">{{ propDef?.title }}</label>
    <div class="text-sm" v-if="propDef?.description">{{ propDef?.description }}</div>
    <div class="mt-2">
      <textarea
        v-if="propDef?.type === 'text'"
        :id="propDef?.name"
        v-model="value"
        rows="2"
        class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
      />
      <div v-else-if="propDef?.type === 'number'" class="relative rounded-md shadow-sm">
        <div v-if="propDef?.prefix" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          :id="propDef?.name"
          v-model="value"
          type="number"
          class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          :class="{ 'pl-7': propDef?.prefix, 'pr-18': propDef?.suffix }"
        />
        <div v-if="propDef?.suffix" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span class="text-gray-500 sm:text-sm" id="price-currency">{{ propDef?.suffix }}</span>
        </div>
      </div>
      <div v-else-if="propDef?.type === 'string'" class="relative rounded-md shadow-sm">
        <input
          :id="propDef?.name"
          v-model="value"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          :class="{ 'pl-7': propDef?.prefix, 'pr-18': propDef?.suffix }"
        />
      </div>
      <div v-else-if="propDef?.type === 'boolean'" class="relative rounded-md flex items-center">
        <input :id="propDef?.name" v-model="value" type="checkbox" class="h-4 w-4 text-indigo-600 rounded focus:ring-indigo-600" />
        <label :for="propDef?.name" class="pl-2 block text-sm font-medium leading-6 text-gray-900">{{ propDef?.title }}</label>
      </div>
      <div v-else-if="propDef?.type === 'audioFile'" class="relative rounded-md shadow-sm">
        <AudioSelector :id="propDef?.name" v-model="value"></AudioSelector>
      </div>
      <div v-else-if="propDef?.type === 'transcriptsCollection'" class="relative rounded-md shadow-sm">
        <CollectionSelector :id="propDef?.name" v-model="value" type="transcripts"></CollectionSelector>
      </div>
      <div v-else-if="propDef?.type === 'recordingsCollection'" class="relative rounded-md shadow-sm">
        <CollectionSelector :id="propDef?.name" v-model="value" type="recordings"></CollectionSelector>
      </div>
      <div v-else-if="propDef?.type === 'kpiCollection'" class="relative rounded-md shadow-sm">
        <CollectionSelector :id="propDef?.name" v-model="value" type="kpi"></CollectionSelector>
      </div>
      <div v-else-if="propDef?.type === 'temperature'" class="px-1 relative rounded-md shadow-sm">
        <div class="flex items-center justify-between">
          <div class="block text-sm leading-6 text-gray-900">More Strict</div>
          <div class="flex-shrink block text-md font-medium leading-6 text-gray-900">{{ propDef?.title }}: {{ value.toFixed(2) }}</div>
          <div class="block text-sm leading-6 text-gray-900">More Creative</div>
        </div>
        <Slider :id="propDef?.name" v-model="value" :min="0" :max="1" :step="0.05" class="mt-2 w-14rem" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import AudioSelector from '@/components/Audio/AudioSelector.vue';
  import CollectionSelector from '@/components/Collections/CollectionSelector.vue';
  import Slider from 'primevue/slider';
  const props = defineProps(['modelValue', 'propDef']);
  const emit = defineEmits(['update:modelValue']);

  const isHidden = computed(() => 'hidden' in props.propDef && props.propDef.hidden);
  const showLabel = computed(() => {
    const t = props.propDef?.title || '';
    return t !== '' && !['boolean', 'temperature'].includes(props.propDef?.type);
  });

  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });
</script>
