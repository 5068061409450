<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-green-700/50 text-white text-lg font-semibold focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
        >{{ currentUserInitial }}</MenuButton
      >
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="px-4 py-3 bg-slate-200 grid place-items-center rounded-t">
          <div class="inline-block h-14 w-14 overflow-hidden rounded-full bg-white">
            <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </div>
          <div class="p-1 text-xl font-medium text-gray-900">{{ authStore.currentUser.displayName }}</div>
          <div class="truncate text-md font-medium text-gray-600">{{ authStore.currentUser.email }}</div>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']">
              <Cog6ToothIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              Settings
            </a>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <div :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer group flex items-center px-4 py-2 text-sm']" @click="onLogout">
              <PowerIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              Sign Out
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { Cog6ToothIcon, CogIcon, PowerIcon } from '@heroicons/vue/24/outline';
  import { useAuthStore } from '@/store/AuthStore';
  import { computed } from 'vue';

  const authStore = useAuthStore();

  const currentUserInitial = computed(() => (authStore?.currentUser?.email || '').charAt(0).toUpperCase());

  function onLogout() {
    authStore.logout();
  }
</script>
