export const JOB_TYPE_INFO = {
  analyzeTranscripts: {
    category: 'analyze',
    type: 'analyzeTranscripts',
    title: 'Analyze Transcripts',
    defaultName: 'New Analyze Transcripts Job',
    icon: 'text-account',
    description: 'Process Conversation Transcripts in JSON format. Summarize, Analyze Sentiment, Problems, KPIs, Agent Strengths, Weaknesses, and Opportunities.',
    collectionType: 'transcripts',
    itemsTitle: 'Transcripts',
    processStep: 'processTranscript',
    scenarioMedia: 'transcript',
  },
  analyzeRecordings: {
    category: 'analyze',
    type: 'analyzeRecordings',
    title: 'Analyze Recordings',
    defaultName: 'New Analyze Recordings Job',
    icon: 'account-voice',
    description: 'Process Audio Recordings. Transcribe, Summarize, Analyze Sentiment, Problems, KPIs, Agent Strengths, Weaknesses, and Opportunities.',
    collectionType: 'recordings',
    itemsTitle: 'Recordings',
    processStep: 'processRecording',
    scenarioMedia: 'recording',
  },
};
