import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import * as firebaseui from 'firebaseui';

const firebaseConfig = {
  apiKey: 'AIzaSyCJPjtsgQ0mqI3hlZB1sfKHh3aKZyccWbg',
  authDomain: 'enetest-68fd3.firebaseapp.com',
  projectId: 'enetest-68fd3',
  storageBucket: 'enetest-68fd3.appspot.com',
  messagingSenderId: '688082823752',
  appId: '1:688082823752:web:053c11a90fb2d44d44467d',
  measurementId: 'G-5DQSXTVF4T',
};

export const fbApp = initializeApp(firebaseConfig);
// TODO Clarify
export const fbAnalytics = getAnalytics(fbApp);
export const fbDb = getFirestore();
export const fbAuth = getAuth();
export const fbFunctions = getFunctions();
export const fbStorage = getStorage();

// If on localhost, use all firebase services locally
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
  connectFirestoreEmulator(fbDb, 'localhost', 8086);
  connectAuthEmulator(fbAuth, 'http://localhost:9099');
  connectFunctionsEmulator(fbFunctions, 'localhost', 5001);
  connectStorageEmulator(fbStorage, 'localhost', 9199);
  // TODO add more services as described in the docs: https://firebase.google.com/docs/emulator-suite/connect_firestore
}

// Make sure it's after emulators setup, otherwise it'll talk to real service
export const fbAuthUI = new firebaseui.auth.AuthUI(fbAuth);
