/*
 * Role Based Access Control Store
 * */
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';

export const useRbacStore = defineStore('rbac', () => {
  const authStore = useAuthStore();

  const navigation = computed(() => {
    if (authStore.userRole === 'agent') {
      return [{ name: 'Agent', to: '/agent' }];
    }
    if (authStore.userRole === 'user') {
      return [
        { name: 'Insights', to: '/insights', current: true },
        { name: 'Agent', to: '/agent', current: true },
        { name: 'Analysis', to: '/analysis', current: true },
        { name: 'Data', to: '/datasets', current: false },
      ];
    }
    if (authStore.userRole === 'admin') {
      return [
        { name: 'Insights', to: '/insights', current: true },
        { name: 'Agent', to: '/agent', current: true },
        { name: 'Analysis', to: '/analysis', current: true },
        { name: 'Data', to: '/datasets', current: false },
        { name: 'Settings', to: '/settings', current: false },
      ];
    }
  });

  const canChangeAgent = computed(() => {
    return authStore.userRole !== 'agent';
  });

  function checkRoute(route) {
    switch (authStore.userRole) {
      case 'agent': {
        // Only agent is allowed for agent
        if (!route.startsWith('/agent')) {
          return { success: false, data: '/agent' };
        }
        break;
      }
      case 'user': {
        // Settings is disabled for users
        if (route.startsWith('/settings')) {
          return { success: false, data: '/' };
        }
        break;
      }
    }
    // OK, route passed
    return {
      success: true,
    };
  }

  return {
    navigation,
    canChangeAgent,
    checkRoute,
  };
});
