<template>
  <div class="mx-6 my-6">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <div class="flex-auto flex items-center space-x-5">
          <div class="flex-shrink-0">
            <div class="relative bg-emerald-600/70 rounded-xl p-1">
              <mdicon name="finance" size="62" class="text-emerald-100" aria-hidden="true"></mdicon>
            </div>
          </div>
          <div class="">
            <h1 class="text-2xl font-bold text-gray-900">Usage</h1>
            <p class="text-sm font-medium text-gray-500">AI and storage consumption statistics and settings</p>
          </div>
        </div>

        <div class="mt-4 relative isolate overflow-hidden">
          <div class="">
            <dl class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-4">
              <div v-for="stat in stats" :key="stat.name" class="relative overflow-hidden rounded-xl border border-gray-200 px-4 pt-4 pb-2">
                <dt class="truncate text-sm font-medium text-gray-500">{{ stat.name }}</dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ stat.value }}</dd>
                <dd class="mt-2 text-sm text-gray-400 font-medium">{{ stat?.description || '' }}</dd>
                <dd v-if="stat?.extraDescription" class="mt-2 text-xs text-gray-400 font-medium">{{ stat?.extraDescription || '' }}</dd>
                <db-easy-pie
                  v-if="stat.gauge"
                  class="-z-10 absolute right-1 top-1 h-28 w-28 rounded-full text-lg font-semibold text-slate-500"
                  :value="stat.value"
                  :max="stat.max"
                  :line-width="8"
                  :animated="true"
                  :percent-ranges="stat?.percentRanges || []"
                  :show-percents="stat.percents || false"
                ></db-easy-pie>
                <mdicon v-else-if="stat.icon" :name="stat.icon" size="105" :class="['-z-10 absolute right-1 top-1 rounded-full', stat.iconClass || '']" />
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';

  const currentAIConsumption = 40;
  const currentStorageConsumption = 60;

  const stats = computed(() => [
    {
      name: 'AI Consumption',
      value: currentAIConsumption,
      max: 100, // totalProblemsScore.value,
      description: `in the current billing cycle`,
      extraDescription: '', //`Out of ${totalProblemsScore.value}`,
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 25, color: '#fde047' },
        { value: 50, color: '#fca5a5' },
      ],
    },
    {
      name: 'Storage Consumption',
      value: currentStorageConsumption,
      max: 100, // totalProblemsScore.value,
      description: `in the current billing cycle`,
      extraDescription: '', //`Out of ${totalProblemsScore.value}`,
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 25, color: '#fde047' },
        { value: 50, color: '#fca5a5' },
      ],
    },
  ]);
</script>
