/*
 * Sidebar Store
 *
 * TODO
 *  Rename to NavStore
 *  Add method: set params - push params for specific route to array, before navigating to that route
 *  After navigated, component can pop params for it's route and process.
 *  This way /edit/:id can decide if it's new document, or not; or pass pre-selected param values to quickstarts
 *  And this allows to avoid passing params via query, so using back button in browser will not interfere
 * */

import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
//import { useDocumentationStore } from '@/store/DocumentationStore';

// Keep it simple - each must start differently
const allSidebarItems = {
  '/insights': [
    { title: 'Agents Activity', link: '/insights/agents/activity', icon: 'face-agent' },
    { title: 'Agents Performance', link: '/insights/agents/performance', icon: 'face-agent' },
    { title: 'Search Conversations', link: '/insights/search', icon: 'text-search' },
  ],
  '/analysis': [
    //{ title: 'Summary', link: '/analysis/none', icon: 'finance' },
    //{ title: 'Tests', link: '/analysis/spec', icon: 'text-box-plus-outline' },
    { title: 'Quick Start', link: '/analysis/quickstart', icon: 'rotate-orbit' },
    //{ title: 'Start Here', link: '/analysis/starthere', icon: 'information-box-outline' },
    //{ title: 'Scenarios', link: '/analysis/scenarios', icon: 'playlist-check' },
    { title: 'Jobs', link: '/analysis/jobs', icon: 'play-box-outline' },
    //{ title: 'Schedule', link: '/analysis', icon: 'calendar-clock-outline' },
    //{ title: 'Jobs Executions', link: '/analysis/jobexecutions', icon: 'play-box-multiple-outline' },
    { title: 'Results', link: '/analysis/results', icon: 'file-document-multiple-outline' },
    //{ title: 'TestRuns(TMP)', link: '/analysis/testruns', icon: 'play-box-outline' },
    //{ title: 'Agents', link: '/analysis/agent', icon: 'face-agent' },
  ],
  '/agent': [
    { title: 'Home', link: '/agent/details/home', icon: 'face-agent' },
    { title: 'Coach', link: '/agent/details/coach', icon: 'account-school-outline' },
    { title: 'Strengths', link: '/agent/details/strengths', icon: 'plus-box-outline' },
    { title: 'Weaknesses', link: '/agent/details/weaknesses', icon: 'minus-box-outline' },
    { title: 'Opportunities', link: '/agent/details/opportunities', icon: 'finance' },
  ],
  '/flows': [
    { title: 'Flows', link: '/flows/flow', icon: 'sitemap-outline' },
    { title: 'Analytics', link: '/flows/search', icon: 'finance' },
  ],
  '/datasets': [
    { title: 'KPI', link: '/datasets/kpi', icon: 'check-circle-outline' },
    { title: 'Transcripts', link: '/datasets/transcripts', icon: 'text-account' },
    { title: 'Recordings', link: '/datasets/recordings', icon: 'account-voice' },
  ],
  '/settings': [
    { title: 'Users', link: '/settings/users', icon: 'account' },
    { title: 'Usage', link: '/settings/usage', icon: 'finance' },
  ],

  // Placeholder for documetation
  //'/documentation': [{ title: 'Getting started', link: '/documentation/getting-started', icon: 'account' }],

  '/components': [
    { title: 'Job', link: '/components/jobtest', icon: 'sitemap' },
    { title: 'Flow', link: '/components/flow', icon: 'sitemap' },
    { title: 'Results', link: '/components/results', icon: 'view-quilt-outline' },
    { title: 'KPIs', link: '/components/kpis', icon: 'view-quilt-outline' },
    { title: 'KPIs Condenced', link: '/components/kpiscondenced', icon: 'view-quilt-outline' },
    { title: 'Test', link: '/components/test', icon: 'view-quilt-outline' },
    { title: 'Spec', link: '/components/spec', icon: 'text-box-plus-outline' },
    { title: 'Pivot', link: '/components/pivot', icon: 'finance' },
    { title: 'Dashboard', link: '/components/dashboard', icon: 'chart-pie-outline' },
    { title: 'Dataset', link: '/components/dsedit', icon: 'chart-pie-outline' },
    { title: 'Test Details', link: '/components/testdetails', icon: 'chart-pie-outline' },
    { title: 'Test Transcript', link: '/components/testtranscript', icon: 'chart-pie-outline' },
    { title: 'Charts', link: '/components/charts', icon: 'chart-pie-outline' },
    { title: 'Charts2', link: '/components/charts2', icon: 'chart-pie-outline' },
    { title: 'Dashboard One', link: '/components/dbone', icon: 'chart-pie-outline' },
    { title: 'Search', link: '/components/search', icon: 'chart-pie-outline' },
  ],
  '/tests': [{ title: 'Schedules', link: 'schedules', icon: 'chart-bar' }],
};

function getSidebarItemsByRoutePath(routePath) {
  const paths = Object.keys(allSidebarItems);
  for (let i = 0; i < paths.length; i++) {
    const pkey = paths[i];
    if (routePath.startsWith(pkey)) {
      return { key: pkey, items: allSidebarItems[pkey] };
    }
  }
  return {
    key: null,
    items: [],
  };
}

export const useSidebarStore = defineStore('sidebar', () => {
  const routePath = ref('');
  const itemsKey = ref(null);
  const items = ref([]);

  //const documentationStore = useDocumentationStore();

  const sidebarItems = computed(() => {
    // route path could be very different,
    // but sidebar items only change when prefix changes
    console.log(`sidebarItems getter called`);
    if (!(itemsKey.value in allSidebarItems)) {
      return [];
    }
    // TODO Remove Keep for now for layout testing
    if (itemsKey.value === '/tests') {
      const extra = [];
      for (let i = 0; i < 12; i++) {
        extra.push({ title: `Test: ${i}`, link: `short?id=${i}`, icon: 'chart-bar' });
      }
      return allSidebarItems[itemsKey.value].concat(extra);
    }
    // Example of special processing for particular path
    //if (state.itemsKey === '/projectdetails') {
    //  console.log(`sidebarItems: handling /projectdetails `);
    // const shorts = rootGetters['projects/projectShorts'];
    //  return allSidebarItems[state.itemsKey].concat(
    //    shorts.map((x) => {
    //      return { title: `Short: ${x.name}`, link: `short?id=${x.id}`, icon: 'chart-bar' };
    //    })
    //  );
    //}
    // default
    return allSidebarItems[itemsKey.value];
  });

  function setRoutePath(newRoutePath) {
    console.log(`SidebarStore: set new routePath ${newRoutePath}`);
    // do not update if routePath not changed
    const { key } = getSidebarItemsByRoutePath(newRoutePath); //altSidebarItems;
    if (routePath.value === newRoutePath) {
      return;
    }
    routePath.value = newRoutePath;
    if (itemsKey.value === key) {
      return;
    }
    itemsKey.value = key;
  }

  return {
    routePath,
    itemsKey,
    items,
    sidebarItems,
    setRoutePath,
  };
});
