<template>
  <div>
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:grid-cols-6 font-source">
      <div class="rounded-lg px-4 py-2 bg-gray-50">
        <div class="text-sm font-normal text-gray-600 py-1 border-b">Conversations</div>
        <div class="text-2xl font-semibold text-gray-600 py-1">{{ agentDataStore.agentConversations }}</div>
        <div class="text-xs font-normal text-gray-500">Total Conversations</div>
      </div>
      <div class="rounded-lg px-4 py-2 bg-gray-50">
        <div class="text-sm font-normal text-gray-600 py-1 border-b">Score</div>
        <div class="text-2xl font-semibold text-gray-600 py-1">{{ agentDataStore.agentAvgConversationScore }}</div>
        <div class="text-xs font-normal text-gray-500">Average Conversations Score</div>
      </div>
      <div class="rounded-lg px-4 py-2 bg-gray-50">
        <div class="text-sm font-normal text-gray-600 py-1 border-b">Min Score</div>
        <div class="text-2xl font-semibold text-gray-600 py-1">{{ agentDataStore.agentMinConversationScore }}</div>
        <div class="text-xs font-normal text-gray-500">Min Conversation Score</div>
      </div>
      <div class="rounded-lg px-4 py-2 bg-gray-50">
        <div class="text-sm font-normal text-gray-600 py-1 border-b">Max Score</div>
        <div class="text-2xl font-semibold text-gray-600 py-1">{{ agentDataStore.agentMaxConversationScore }}</div>
        <div class="text-xs font-normal text-gray-500">Max Conversation Score</div>
      </div>
      <div class="rounded-lg px-4 py-2 bg-gray-50">
        <div class="text-sm font-normal text-gray-600 py-1 border-b">Problems Severity</div>
        <div class="text-2xl font-semibold text-gray-600 py-1">{{ agentDataStore.agentAvgProblemsSeverity }}</div>
        <div class="text-xs font-normal text-gray-500">Average Problems Severity</div>
      </div>
      <div class="rounded-lg px-4 py-2 bg-gray-50">
        <div class="text-sm font-normal text-gray-600 py-1 border-b">KPI Score</div>
        <div class="text-2xl font-semibold text-gray-600 py-1">{{ agentDataStore.agentAvgKPIScore }}</div>
        <div class="text-xs font-normal text-gray-500">Average KPI Score</div>
      </div>
    </div>

    <div class="pt-4 grid grid-cols-1 gap-x-6 gap-y-8 xl:grid-cols-3 font-source">
      <div class="bg-gray-50 rounded-lg px-4 py-2">
        <div class="text-sm font-semibold text-gray-600 border-b py-1">Your Strengths</div>
        <EqListCondensed class="mt-2" :placeholder="agentDataStore.analyzing" :items="agentDataStore.agentStrengths" nameProp="name" color="#065f46"></EqListCondensed>
      </div>
      <div class="bg-gray-50 rounded-lg px-4 py-2">
        <div class="text-sm font-semibold text-gray-600 border-b py-1">Your Weaknesses</div>
        <EqListCondensed class="mt-2" :placeholder="agentDataStore.analyzing" :items="agentDataStore.agentWeaknesses" nameProp="name" color="#991b1b"></EqListCondensed>
      </div>
      <div class="bg-gray-50 rounded-lg px-4 py-2">
        <div class="text-sm font-semibold text-gray-600 border-b py-1">Your Opportunities</div>
        <EqListCondensed class="mt-2" :placeholder="agentDataStore.analyzing" :items="agentDataStore.agentOpportunities" nameProp="name" color="#065f46"></EqListCondensed>
      </div>
    </div>

    <div class="pt-4 text-sm font-semibold leading-7 text-gray-600 sm:truncate sm:tracking-tight mb-4">Personal Coach Suggestions</div>
    <div class="">
      <Message v-if="agentDataStore.generatingCoachingSuggestions" severity="info">Creating New Coaching Suggestions ...</Message>
      <!--<AgentCoachingSuggestion :suggestion="agentDataStore.coachingSuggestions[0] || null"></AgentCoachingSuggestion>-->
      <AgentCoachingSuggestion :suggestion="firstSuggestion" :ack="false"></AgentCoachingSuggestion>
      <!--<div v-for="(s, idx) in agentDataStore.coachingSuggestions" :key="idx">{{ s.text }}</div>-->
      <div class="flex justify-end">
        <router-link :to="{ path: `/agent/details/coach` }">
          <div
            class="mr-2 flex items-center gap-x-1 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <mdicon name="account-school-outline" size="20" class="text-orange-500/70" aria-hidden="true"></mdicon>
            More Suggestions from Coach ...
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useAgentDataStore } from '@/store/AgentDataStore';
  import EqListCondensed from '@/components/Charts/EqListCondensed.vue';
  import Message from 'primevue/message';
  import AgentCoachingSuggestion from '@/components/Agents/AgentCoachingSuggestion.vue';

  const agentDataStore = useAgentDataStore();

  const firstSuggestion = computed(() => {
    return agentDataStore.coachingSuggestions[0] || {};
  });
</script>
