<template>
  <div class="">
    <div class="relative isolate overflow-hidden">
      <div class="">
        <dl class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-4">
          <div v-for="stat in stats" :key="stat.name" class="relative overflow-hidden rounded-xl border border-gray-200 px-4 pt-4 pb-2">
            <dt class="truncate text-sm font-medium text-gray-500">{{ stat.name }}</dt>
            <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ stat.value }}</dd>
            <dd class="mt-2 text-sm text-gray-400 font-medium">{{ stat?.description || '' }}</dd>
            <dd v-if="stat?.extraDescription" class="mt-2 text-xs text-gray-400 font-medium">{{ stat?.extraDescription || '' }}</dd>
            <db-easy-pie
              v-if="stat.gauge"
              class="-z-10 absolute right-1 top-1 h-28 w-28 rounded-full text-lg font-semibold text-slate-500"
              :value="stat.value"
              :max="stat.max"
              :line-width="8"
              :animated="true"
              :percent-ranges="stat?.percentRanges || []"
              :show-percents="stat.percents || false"
            ></db-easy-pie>
            <mdicon v-else-if="stat.icon" :name="stat.icon" size="105" :class="['-z-10 absolute right-1 top-1 rounded-full', stat.iconClass || '']" />
          </div>
        </dl>
      </div>

      <!--<div class="mt-10 border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
        <dl class="grid max-w-7xl grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <div v-for="(stat, statIdx) in stats" :key="stat.name" class="m-4 relative flex items-baseline flex-wrap justify-between pt-8 pb-4 py-4 sm:px-6 xl:px-8">
            <dt class="text-sm font-medium leading-6 text-gray-500">{{ stat.name }}</dt>
            <dd :class="[stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700', 'text-xs font-medium']">{{ stat.change }}</dd>
            <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">{{ stat.value }}</dd>
            <dd class="text-xs font-medium">{{ stat?.description || '' }}</dd>
          </div>
        </dl>
      </div>-->
    </div>

    <div v-if="conversationSummary && conversationSummary !== ''" class="overflow-hidden rounded-xl border border-gray-200 mt-6">
      <div class="flex items-center border-b border-gray-900/5 bg-gray-50 p-2">
        <mdicon name="text" size="18" class="mx-2 rounded-md bg-violet-600/70 p-1 text-violet-100" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Summary</h2>
      </div>
      <div class="p-2">
        <div class="ml-2 font-source text-lg text-slate-600">
          {{ conversationSummary }}
        </div>
      </div>
    </div>

    <div v-if="sentimentExplanation && sentimentExplanation !== ''" class="overflow-hidden rounded-xl border border-gray-200 mt-6">
      <div class="flex items-center border-b border-gray-900/5 bg-gray-50 p-2">
        <mdicon :name="sentimentIcon" size="18" class="mx-2 rounded-md bg-violet-600/70 p-1 text-violet-100" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Customer Sentiment: {{ customerSentiment }}</h2>
      </div>
      <div class="p-2">
        <div class="ml-2 font-source text-lg text-slate-600">
          {{ sentimentExplanation }}
        </div>
      </div>
    </div>

    <div class="space-y-8 py-4 xl:space-y-8">
      <div class="">
        <div class="mx-auto lg:mx-0 lg:max-w-none">
          <ul role="list" class="mt-4 grid grid-cols-1 gap-x-0 gap-y-4 lg:grid-cols-4 lg:gap-x-6 xl:gap-x-8">
            <li v-for="detailsItem in details" :key="detailsItem.id" :class="[detailsItem.class, 'overflow-hidden rounded-xl border border-gray-200']">
              <div class="flex items-center border-b border-gray-900/5 bg-gray-50 p-2">
                <mdicon :name="detailsItem.icon" size="18" class="mx-2 rounded-md bg-violet-600/70 p-1 text-violet-100" aria-hidden="true"></mdicon>
                <h2 class="text-base font-semibold leading-7 text-gray-900">{{ detailsItem.name }}</h2>
              </div>
              <dl class="-my-3 divide-y divide-gray-100 px-6 pt-2 pb-4 text-sm leading-6">
                <div v-for="(entry, idx) in detailsItem.entries" :key="idx">
                  <div class="flex justify-between gap-x-4 py-2">
                    <dt class="text-gray-500">{{ entry.name }}</dt>
                    <dd class="text-gray-700 font-semibold">
                      {{ entry.value }}
                      <!--<time :datetime="detailsItem.lastInvoice.dateTime">{{ detailsItem.lastInvoice.date }}</time>-->
                    </dd>
                  </div>
                  <equalizer-value v-if="entry.eq" class="mb-2" :value="entry.value || 0" :max="10" :height="8" color="#9f1239"></equalizer-value>
                  <equalizer-value v-else-if="entry.eqc" class="mb-2" :value="entry.value" :max="10" :height="8" :reverse="true" color-mode="uniform"></equalizer-value>
                </div>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="overflow-hidden rounded-xl border border-gray-200 mt-6">
      <div class="flex items-center border-b border-gray-900/5 bg-gray-50 p-2">
        <mdicon name="text" size="18" class="mx-2 rounded-md bg-violet-600/70 p-1 text-violet-100" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Detected Properties</h2>
      </div>
      <div class="p-2">
        <div v-for="(item, idx) in analysisDetected" :key="idx">
          <div class="m-2 flex justify-start border-b">
            <dt class="text-gray-500">{{ item.description }}:</dt>
            <dd class="ml-2 text-gray-700 font-semibold">
              {{ item.result }}
            </dd>
          </div>
          <div class="m-2">{{ item.explanation }}</div>
        </div>
      </div>
    </div>

    <div class="overflow-hidden rounded-xl border border-gray-200 mt-6">
      <div class="flex items-center border-b border-gray-900/5 bg-gray-50 p-2">
        <mdicon name="text" size="18" class="mx-2 rounded-md bg-violet-600/70 p-1 text-violet-100" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Conversation Details</h2>
      </div>
      <div class="p-2">
        <ConversationMetadata :conversation="conversation"></ConversationMetadata>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon, Bars3Icon, EllipsisHorizontalIcon, PlusSmallIcon } from '@heroicons/vue/20/solid';
  import { BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';
  import EqualizerValue from '@/components/Charts/EqualizerValue.vue';
  import ConversationMetadata from '@/components/Insights/ConversationMetadata.vue';

  const props = defineProps({
    conversation: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  //const conversationId = computed(() => props.conversation?.id || '');
  const conversationDuration = computed(() => props.conversation?.context?.transcript?.metadata?.duration || 0);
  const conversationDatetime = computed(() => props.conversation?.datetime || '');

  const conversationScore = computed(() => props.conversation?.score || 0);

  const sumKPIsScore = computed(() => (props.conversation.context?.analysis?.kpis || []).reduce((sum, v) => sum + parseInt(v?.score || 0), 0));
  const totalKPIsScore = computed(() => (props.conversation.context?.analysis?.kpis || []).length * 10);
  const sumProblemsScore = computed(() => (props.conversation.context?.analysis?.problems || []).reduce((sum, v) => sum + parseInt(v?.severity || v?.importance || 0), 0));
  const totalProblemsScore = computed(() => (props.conversation.context?.analysis?.problems || []).length * 10);

  const conversationSummary = computed(() => props.conversation.context?.analysis?.summary || null);

  //const customerSentiment = computed(() => -10);
  const customerSentiment = computed(() => props.conversation.context?.analysis?.sentiment?.customerSentiment || 0);

  const sentimentExplanation = computed(() => props.conversation.context?.analysis?.sentiment?.explanation || '');

  const sentimentIcon = computed(() => (customerSentiment.value < 0 ? 'emoticon-angry-outline' : customerSentiment.value > 0 ? 'emoticon-happy-outline' : 'emoticon-neutral-outline'));

  const conversationTranscript = computed(() => props.conversation?.context?.transcript || {});
  const conversationTranscriptMetadata = computed(() => conversationTranscript.value?.metadata || {});

  const scenarioId = computed(() => props.conversation.scenario?.id || '');
  const scenarioName = computed(() => props.conversation.scenario?.name || '');
  const scenarioMedia = computed(() => props.conversation.scenario?.media || '');
  const analysisProblems = computed(() => props.conversation.context?.analysis?.problems || []);
  const analysisKPIs = computed(() => props.conversation.context?.analysis?.kpis || []);
  const analysisDetected = computed(() => props.conversation.context?.analysis?.detected || []);

  function formatSeconds(sec) {
    const totalMinutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    //const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  const stats = computed(() => [
    /*{
      name: 'Duration',
      value: formatSeconds(conversationDuration.value),
      icon: 'clock-outline',
      iconClass: 'text-gray-200/60',
      description: conversationDatetime.value,
      extraDescription: ``,
    },*/
    {
      name: 'Score',
      value: conversationScore.value,
      icon: 'finance',
      iconClass: 'text-gray-200/60',
      description: 'Overall Conversation Score',
      extraDescription: `Higher is better`,
    },
    {
      name: 'Sentiment',
      value: customerSentiment.value,
      icon: sentimentIcon.value,
      iconClass: customerSentiment.value < 0 ? 'text-red-500/70' : customerSentiment.value > 0 ? 'text-green-500/70' : 'text-gray-200/60',
      description: `From -100 to 100`,
      extraDescription: `-100 is highly negative, 100 is highly positive`,
    },
    {
      name: 'Problems Severity',
      value: sumProblemsScore.value,
      max: 100, // totalProblemsScore.value,
      description: `Smaller is better`,
      extraDescription: '', //`Out of ${totalProblemsScore.value}`,
      gauge: true,
      percents: false,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 25, color: '#fde047' },
        { value: 50, color: '#fca5a5' },
      ],
    },
    {
      name: 'KPI Score',
      value: sumKPIsScore.value,
      max: totalKPIsScore.value,
      description: 'Higher is better',
      extraDescription: `Out of ${totalKPIsScore.value} (${totalKPIsScore.value / 10} KPIs)`,
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 50, color: '#fde047' },
        { value: 75, color: '#bef264' },
      ],
    },
    /*
    {
      name: 'MOS Score',
      value: scenarioResultsStore.mosScore,
      max: 4.5,
      description: `Max MOS score is 4.5`,
      percents: true,
      gauge: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 80, color: '#fde047' },
        { value: 90, color: '#bef264' },
      ],
    },*/
  ]);

  const details = computed(() => [
    /*{
      id: 1,
      name: 'Metadata',
      class: 'col-span-1',
      icon: 'cog',
      entries: Object.keys(conversationTranscriptMetadata.value).map((x) => {
        return { name: x, value: conversationTranscriptMetadata.value[x] };
      }),
      entries2: [
        { name: 'ID', value: scenarioId.value },
        { name: 'Name', value: scenarioName.value },
        { name: 'Media', value: scenarioMedia.value },
      ],
    },*/
    {
      id: 1,
      name: 'KPIs',
      class: 'col-span-2',
      icon: 'alert-circle-outline',
      entries: analysisKPIs.value.map((x) => {
        return { name: x.kpi || '', value: parseInt(x?.score || 0), eqc: true };
      }),
    },
    {
      id: 2,
      name: 'Problems',
      class: 'col-span-2',
      icon: 'alert-circle-outline',
      entries: analysisProblems.value.map((x) => {
        return { name: x.problem || '', value: parseInt(x?.severity || x?.importance || 0), eq: true };
      }),
    },
  ]);
</script>
