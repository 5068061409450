<template>
  <div class="my-2">
    <SwitchGroup as="div" class="flex items-center bg-gray-50 px-2 py-2 rounded-md">
      <Switch
        v-model="enabled"
        :class="[
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
        ]"
      >
        <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
      </Switch>
      <SwitchLabel as="div" class="ml-3">
        <div :class="[enabled ? 'text-gray-700' : 'text-gray-500', 'font-semibold text-md']">{{ name }}</div>
      </SwitchLabel>
    </SwitchGroup>
    <div class="py-2 px-2">
      <div class="font-normal text-sm text-gray-600">{{ description }}</div>
      <slot v-if="enabled"></slot>
    </div>
  </div>
</template>
<script setup>
  import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
  import { computed } from 'vue';

  const props = defineProps(['name', 'description', 'modelValue']);
  const emit = defineEmits(['update:modelValue']);

  const enabled = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });
</script>
