<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-7 text-gray-900">Settings</h1>
        <p class="mt-2 text-sm text-gray-700">Configure all your settings here</p>
      </div>

      <div class="divide-x divide-gray-100 my-2"><p></p></div>

      <div class="mt-1">
        <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
          </select>
        </div>
        <div class="hidden sm:block">
          <nav class="flex space-x-4 rounded-md" aria-label="Tabs">
            <button
              v-for="tab in tabs"
              :key="tab.name"
              :href="tab.href"
              :class="[tab.name === currentTab ? 'bg-slate-300/50 text-gray-800' : 'bg-slate-100/80 text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']"
              @click="onSelectTab(tab.name)"
            >
              {{ tab.name }}
            </button>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="divide-y divide-gray-100 my-2">
    <p></p>
    <UsersList v-if="isTabVisible('Users')"></UsersList>
    <Usage v-if="isTabVisible('Usage')"></Usage>
  </div>
</template>

<script setup>
  import UsersList from '@/components/Settings/UsersList.vue';
  import Usage from '@/components/Settings/Usage.vue';

  import { ref } from 'vue';

  const tabs = [
    { name: 'Users', href: '#', current: false },
    { name: 'Usage', href: '#', current: false },
  ];
  const currentTab = ref(tabs[0].name);

  function onSelectTab(tabName) {
    currentTab.value = tabName;
  }

  function isTabVisible(tabName) {
    return currentTab.value === tabName;
  }
</script>
