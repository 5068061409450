<template>
  <DataTable :value="agents" sortField="scoreAvg" :sortOrder="-1" removableSort tableStyle="min-width: 50rem" class="p-datatable-sm">
    <Column field="agentName" header="Agent" sortable class="">
      <template #body="slotProps">
        <div class="flex items-center">
          <mdicon name="face-agent" size="18" class="p-1 mr-2 text-slate-50 bg-slate-400/50 rounded-md" aria-hidden="true"></mdicon>
          <router-link :to="{ path: `/agent/details/home`, query: { agentId: slotProps.data.agentId } }">
            <div class="cursor-pointer font-medium hover:underline">{{ slotProps.data?.agentName || '-' }}</div>
          </router-link>
        </div>
      </template>
    </Column>
    <Column field="agentId" header="AgentID" sortable class="">
      <template #body="slotProps">
        <router-link :to="{ path: `/agent/details/home`, query: { agentId: slotProps.data.agentId } }">
          <div class="cursor-pointer font-medium hover:underline">{{ slotProps.data?.agentId || '-' }}</div>
        </router-link>
      </template>
    </Column>
    <Column field="conversationsCount" header="Conversations" sortable>
      <template #body="slotProps">
        <router-link :to="{ path: `/insights/search`, query: { query: getSearchQueryConversations(slotProps.data) } }" class="flex items-center hover:underline">
          <div class="cursor-pointer font-medium">{{ slotProps.data.conversationsCount }}</div>
          <mdicon name="open-in-new" size="20" class="ml-2 text-gray-300"></mdicon>
        </router-link>
      </template>
    </Column>
    <Column field="scoreAvg" header="Score Avg" sortable></Column>
    <Column field="scoreMin" header="Score Min" sortable></Column>
    <Column field="scoreMax" header="Score Max" sortable></Column>
  </DataTable>
</template>

<script setup>
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';

  defineProps({
    agents: {
      type: Array,
      default() {
        return [];
      },
    },
  });

  function getSearchQueryConversations(data) {
    return encodeURIComponent(`AgentId:"${data.agentId}"`);
  }
</script>
