import { onMounted, reactive } from 'vue';
import { pathOr } from 'ramda';

// TODO Fix - does not work in prod build
//import resolveConfig from 'tailwindcss/resolveConfig';
//import * as tailwindConfig from 'tailwind.config.js';
const fullTailwindConfig = {}; //resolveConfig(tailwindConfig);

export const screens = {
  sm: parseInt(pathOr('640', ['theme', 'screens', 'sm'], fullTailwindConfig)),
  md: parseInt(pathOr('768', ['theme', 'screens', 'md'], fullTailwindConfig)),
  lg: parseInt(pathOr('1024', ['theme', 'screens', 'lg'], fullTailwindConfig)),
  xl: parseInt(pathOr('1280', ['theme', 'screens', 'lg'], fullTailwindConfig)),
};

const breakpoints = reactive({ w: 0, h: 0, is: 'sm' });

const sm = (val) => val >= screens.sm && val < screens.md;
const md = (val) => val >= screens.md && val < screens.lg;
const lg = (val) => val >= screens.lg && val < screens.xl;
const xl = (val) => val >= screens.xl;

const getBreakpoint = (w) => {
  if (sm(w)) return 'sm';
  else if (md(w)) return 'md';
  else if (lg(w)) return 'lg';
  else if (xl(w)) return 'xl';
  else return 'xs';
};

const setBreakpoint = () => {
  breakpoints.w = window.innerWidth;
  breakpoints.h = window.innerHeight;
  breakpoints.is = getBreakpoint(window.innerWidth);
};

export const useBreakpoint = () => {
  onMounted(() => {
    setBreakpoint();
    window.addEventListener('resize', () => {
      setBreakpoint();
      console.log(`resize: ${JSON.stringify(breakpoints)}`);
    });
    /* Pointless
    window.addEventListener('beforeprint', () => {
      // Force it to sm when printing
      breakpoints.w = 640;
      breakpoints.h = window.innerHeight;
      breakpoints.is = getBreakpoint(640);
      console.log(`beforeprint: Screens2: ${JSON.stringify(breakpoints)}`);
    });
    window.addEventListener('afterprint', () => {
      //setBreakpoint();
      console.log(`afterprint: Screens2: ${JSON.stringify(breakpoints)}`);
    }); */
  });

  return {
    breakpoints,
  };
};
