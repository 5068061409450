/*
 * Auth Store
 * Provides data about currently logged-in user, and user management services
 *
 * Links:
 *
 * https://medium.com/firebase-developers/generating-email-action-links-with-the-firebase-admin-sdk-4b9d5e2cf914
 *
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { fbAuth, fbDb } from '@/firebaseApp';
import { onAuthStateChanged, onIdTokenChanged, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, collection, query, where, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { getCurrentUser } from 'vuefire';
import { useToast } from 'vue-toastification';

export const useAuthStore = defineStore('auth', () => {
  // Current user (firebase Auth User)
  const currentUser = ref(null);

  // Current user Data - user document from firestore users collection
  const currentUserData = ref(null);

  const loggedIn = ref(false);

  const tenantId = ref(null);
  const userRole = ref(null);

  const userId = computed(() => currentUser.value?.uid || null);
  const agentId = computed(() => currentUserData.value?.agentId || null);
  const displayName = computed(() => currentUserData.value?.displayName || null);

  const toast = useToast();

  function init() {
    onAuthStateChanged(fbAuth, async (user) => {
      if (user) {
        console.log(`onAuthStateChanged: LOGGED IN: userId=${user.uid} email=${user.email}`);
        loggedIn.value = true;
      } else {
        console.log(`onAuthStateChanged: LOGGED OUT`);
        tenantId.value = null;
        userRole.value = null;
        currentUser.value = null;
        currentUserData.value = null;
        const wasLoggedIn = loggedIn.value;
        loggedIn.value = false;
        if (wasLoggedIn) {
          // Do not use router here - make full page refresh to fully clean up all states
          window.location.href = '/'; // navigate to root on log out
        }
      }
    });

    // TODO Is this called when changing custom claims ?
    onIdTokenChanged(fbAuth, async (user) => {
      console.log(`onIdTokenChanged: user: ${JSON.stringify(user)}`);
      //const idTokenResult = await fbAuth.currentUser.getIdTokenResult();
      //console.log(`onIdTokenChanged: ${JSON.stringify(idTokenResult)}`);
    });
  }

  function logout() {
    signOut(fbAuth)
      .then(() => {
        console.log(`Logged out user`);
      })
      .catch((error) => {
        console.log(`Error when logging out: ${error.message}`);
      });
  }

  async function getCurrentUserInfo() {
    console.log(`auth: getCurrentUserInfo ...`);
    const user = await getCurrentUser();
    if (!user) {
      console.log(`auth: getCurrentUserInfo: NULL`);
      return null;
    }
    console.log(`auth: getCurrentUserInfo: userId=${user.uid}`);
    if (!currentUser.value || currentUser.value?.uid !== user?.uid) {
      console.log(`auth: getCurrentUserInfo: setting up user ...`);
      currentUser.value = user;

      console.log(`auth: getCurrentUserInfo: getting claims ...`);
      let idTokenResult = null;
      try {
        idTokenResult = await fbAuth.currentUser.getIdTokenResult();
      } catch (e) {
        const msg = `ERROR: Failed to get claims for user ${user.uid} ${user.email}: ${e.message} - not allowed to log in`;
        console.log(msg);
        toast.error(msg);
        logout();
        return;
      }

      const userTenantId = user?.tenantId || null;
      const userOrgId = idTokenResult?.claims?.orgId || null;

      console.log(`onAuthStateChanged: Got claims: Org: ${userOrgId}`);

      if (!userTenantId && !userOrgId) {
        const msg = `ERROR: User ${user.uid} ${user.email} has no tenantId - not allowed to log in`;
        console.log(msg);
        toast.error(msg);
        logout();
        return;
      }

      // Either tenantId for tenant-enabled users, or orgId for regular users
      tenantId.value = userTenantId ? userTenantId : userOrgId;
      userRole.value = idTokenResult?.claims?.role || 'user';

      console.log(`auth: getCurrentUserInfo: loading user data ...`);
      const uDataDoc = await getDoc(doc(fbDb, `tenants/${tenantId.value}/users`, user.uid));
      if (uDataDoc.exists()) {
        currentUserData.value = uDataDoc.data();
      }

      // Set last login date, if needed
      const lastLoginAt = parseInt(user?.metadata?.lastLoginAt || 0);
      if (lastLoginAt !== currentUserData.value?.lastActive) {
        console.log(`auth: getCurrentUserInfo: updating last login date ...`);
        await setCurrentUserLastActiveDate(user);
      }
    }
    return user;
  }

  // Find User by AgentID
  async function getAgentUserData(agentId) {
    const usersRef = collection(fbDb, `tenants/${tenantId.value}/users`);
    const q = query(usersRef, where('agentId', '==', agentId));
    const querySnapshot = await getDocs(q);
    const agentUserData = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, ' => ', doc.data());
      agentUserData.push(Object.assign({}, doc.data(), { id: doc.id }));
    });
    // Should not be more than one
    if (agentUserData.length > 1) {
      // TODO Toast
      console.log(`Error: more than one user with the same agentId=${agentId}`);
      agentUserData.map((x) => {
        console.log(`User: ${x.email} ${x.displayName} agentId=${agentId}`);
      });
      return null;
    }
    return agentUserData.length > 0 ? agentUserData[0] : null;
  }

  async function addUser(userData) {
    const agentId = userData?.agentId || '';
    if (agentId !== '') {
      // Check for duplicates
      const agentUserData = await getAgentUserData(agentId);
      if (agentUserData !== null) {
        const msg = `ERROR: Duplicated agentId ${agentId}: user ${agentUserData.displayName} already has agentId=${agentId}`;
        console.log(msg);
        return { success: false, error: msg };
      }
    }

    const functions = getFunctions();
    const addUserFunction = httpsCallable(functions, 'addUser');
    let result = null;
    try {
      result = await addUserFunction(userData);
    } catch (e) {
      const msg = `ERROR: User could not be added: ${e.message}`;
      console.log(msg);
      return { success: false, error: msg };
    }

    console.log(`Add user result: ${JSON.stringify(result)}`);
    return result?.data || { success: false, error: 'Internal error' };
  }

  async function updateUser(userData, oldUserData) {
    const userId = userData?.id || null;
    const agentId = userData?.agentId || '';
    if (agentId !== '') {
      // Check for duplicates
      const agentUserData = await getAgentUserData(agentId);
      // TODO Only if it is not the same user !
      if (agentUserData !== null && agentUserData.id !== userId) {
        const msg = `ERROR: Duplicated agentId ${agentId}: user ${agentUserData.displayName} already has agentId=${agentId}`;
        console.log(msg);
        return { success: false, error: msg };
      }
    }

    // Check if we need to update Auth user
    if (userData.role !== oldUserData.role || userData.displayName !== oldUserData.displayName) {
      const props = {
        uid: userId,
      };
      if (userData.role !== oldUserData.role) {
        props.role = userData.role;
      }
      if (userData.displayName !== oldUserData.displayName) {
        props.displayName = userData.displayName;
      }
      let updateResult = null;
      try {
        updateResult = await updateAuthUser(props);
      } catch (e) {
        const msg = `ERROR: Failed to update user: ${e.message}`;
        console.log(msg);
        return { success: false, error: msg };
      }
      if (!updateResult.success) {
        return updateResult;
      }
    }

    // update user doc in collection
    try {
      await updateDoc(doc(fbDb, `tenants/${tenantId.value}/users`, userData.id), userData);
    } catch (e) {
      const msg = `ERROR: Failed to update user: ${e.message}`;
      console.log(msg);
      return { success: false, error: msg };
    }

    return { success: true };
  }

  async function setCurrentUserLastActiveDate(user = null) {
    if (!user || !user.uid) {
      return;
    }
    const lastLoginAt = parseInt(user?.metadata?.lastLoginAt || Date.now());
    try {
      const userData = {
        lastActive: lastLoginAt,
      };
      await updateDoc(doc(fbDb, `tenants/${tenantId.value}/users`, user.uid), userData);
    } catch (e) {
      const msg = `ERROR: Failed to update user: ${e.message}`;
      console.log(msg);
    }
  }

  async function setUserRole(uid, role) {
    return updateAuthUser({
      uid: uid,
      role: role,
    });
  }

  async function updateAuthUser(props) {
    const functions = getFunctions();
    const updateAuthUserFunction = httpsCallable(functions, 'updateAuthUser');
    let result = null;
    try {
      result = await updateAuthUserFunction(props);
    } catch (e) {
      const msg = `ERROR: Could not update user: ${e.message}`;
      console.log(msg);
      return {
        success: false,
        error: e.message,
      };
    }
    return result?.data;
  }

  async function callTestFunction() {
    const functions = getFunctions();
    const testFunction = httpsCallable(functions, 'testFunction');
    let result = null;
    try {
      result = await testFunction({});
    } catch (e) {
      const msg = `ERROR: Could not call test function: ${e.message}`;
      console.log(msg);
      toast.error(msg);
      return false;
    }

    console.log(`Test function result: ${JSON.stringify(result)}`);
    return result;
  }

  return {
    loggedIn,
    tenantId,
    userId,
    userRole,
    agentId,
    displayName,
    currentUser,
    currentUserData,
    init,
    getCurrentUserInfo,
    addUser,
    updateUser,
    setUserRole,
    callTestFunction,
    logout,
    getAgentUserData,
  };
});
