<template>
  <div class="relative isolate overflow-hidden bg-white">
    <div class="-z-10 absolute inset-0 bottom-10 bg-bottom bg-no-repeat bg-slate-50 dark:bg-[#0B1120] beams-5"></div>
    <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
        <logo class="w-auto h-6 lg:h-12"></logo>
        <h1 class="mt-10 text-4xl font-bold tracking-tight text-gray-900 lg:text-6xl">AI Powered<br />CX Improvement</h1>
        <p class="hidden lg:block mt-6 text-lg text-slate-600 max-w-3xl mx-auto dark:text-slate-400">
          In today's digital world, <span class="font-medium text-teal-700 dark:text-teal-400">Customer Experience </span>is a crucial factor for businesses to succeed.
          <span class="font-medium text-teal-700 dark:text-teal-400">A Positive CX, </span>can lead to increased customer <span class="font-medium text-teal-700 dark:text-teal-400">Satisfaction, Loyalty </span> and
          ultimately drive
          <span class="font-medium text-teal-700 dark:text-teal-400">Revenue Growth.</span>
        </p>
        <div class="hidden lg:flex mt-10 items-center gap-x-6">
          <a href="https://enegel.com" target="_blank" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
        </div>
      </div>
      <div class="mx-auto lg:mt-12 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
        <div class="mt-20 max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
          <div class="w-[400px]" id="firebaseui-auth-container"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // References - to review more and implement
  //
  // Passing state & URL in email actions:
  // https://firebase.google.com/docs/auth/web/passing-state-in-email-actions
  // Custom email handler
  // https://firebase.google.com/docs/auth/custom-email-handler
  // Sample of firebase auth flows with Vue
  // https://redfern.dev/articles/email-verification-firebase-vuejs/
  import Logo from '@/components/Navbars/Logo.vue';
  import { onMounted } from 'vue';
  import firebase from 'firebase/compat/app';
  import 'firebaseui/dist/firebaseui.css';
  import { fbAuth, fbAuthUI } from '@/firebaseApp';
  import { sendEmailVerification } from 'firebase/auth';
  import { useRouter, useRoute } from 'vue-router';

  const firebaseAuthUIOptions = {
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
        disableSignUp: {
          status: true,
        },
      },
    ],
    callbacks: {
      //signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      //  signInAttemptSuccessful(authResult, redirectUrl);
      //},
      signInSuccessWithAuthResult: signInAttemptSuccessful,
      signInFailure: signInAttemptFailure,
      uiShown: firebaseUiShown,
    },
  };

  const route = useRoute();
  const router = useRouter();

  function signInAttemptSuccessful(authResult, redirectUrl) {
    console.log('authResult', authResult);
    //console.log('redirectUrl', redirectUrl);
    // Do what you wish with authResult... save to session, cookie, etc.
    const dest = route?.query?.redirect || '/';
    router.push(dest);
  }
  // Note, bad credentials is not a sign-in failure
  function signInAttemptFailure(error) {
    console.log('error', error);
  }
  function firebaseUiShown() {
    console.log('Firebase UI widget rendered');
  }

  onMounted(() => {
    // Initialize Auth store
    fbAuthUI.start('#firebaseui-auth-container', firebaseAuthUIOptions);
  });
</script>
