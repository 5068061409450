/*
 * Agent Store
 * */
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useRbacStore } from '@/store/RBACStore';
import { useAnalyticsStore } from '@/store/AnalyticsStore';
import { useDateRangeStore } from '@/store/DaterangeStore';
import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';
import { doc, setDoc, getDoc, collection, deleteDoc } from 'firebase/firestore';
import { fbDb } from '@/firebaseApp';
import { useCollection } from 'vuefire';
import { useToast } from 'vue-toastification';
import { getFunctions, httpsCallable } from 'firebase/functions';

const pause = (x) => new Promise((res) => setTimeout(res, x));

export const useAgentDataStore = defineStore('agentData', () => {
  const authStore = useAuthStore();
  const rbac = useRbacStore();
  const analyticsStore = useAnalyticsStore();
  const dateRangeStore = useDateRangeStore();

  const toast = useToast();

  // agentId - the agent who is being analyzed
  // By default, it is the same user as who is logged in.
  const agentId = ref(null);
  const agentName = ref(null);
  const agentProperties = ref(null);
  const agentSuggestionsAdded = computed(() => (agentProperties.value ? agentProperties.value?.suggestionsAdded || 0 : 0));

  // Analyzing state
  const analyzing = ref(false);
  const analyzedTimeInterval = ref(null);
  const analysisResultsAvailable = ref(false);
  const generatingCoachingSuggestions = ref(false);

  // Results
  const agentConversations = ref(0);
  const agentAvgConversationScore = ref(0);
  const agentMinConversationScore = ref(0);
  const agentMaxConversationScore = ref(0);
  const agentAvgSentiment = ref(0);
  const agentAvgProblemsSeverity = ref(0);
  const agentAvgKPIScore = ref(0);
  const agentWeaknesses = ref([]);
  const agentStrengths = ref([]);
  const agentOpportunities = ref([]);

  const suggestionsCollection = computed(() => (agentId.value ? collection(fbDb, `tenants/${authStore.tenantId}/agents/${agentId.value}/suggestions`) : null));
  const coachingSuggestions = useCollection(suggestionsCollection);

  async function init() {
    // Current User may be not an agent, i.e. do not have agentId set
    if (agentId.value === null) {
      agentId.value = authStore.agentId;
      agentName.value = authStore.displayName;
      agentProperties.value = await loadAgentProperties(agentId.value);

      if (agentId.value !== null) {
        // We only need to do this once for an agent
        checkCoachingSuggestions().then(() => {
          console.log(`checkCoachingSuggestions complete`);
        });
      }
    }

    if (agentId.value !== null) {
      // Check if we need to re-analyze
      if (analysisResultsAvailable.value && dateRangeStore.isSameTimeInterval(analyzedTimeInterval.value)) {
        // No need to re-analyze
        return;
      }
      await analyzeAgentData();
    }
  }

  // Set Specific Agent to Analyze
  async function setAgent(newAgentId) {
    // TODO Enable
    //if (agentId.value === newAgentId) {
    //  return;
    //}

    // Protection if current user has role agent
    //if (authStore.userRole !== 'admin' && authStore.userRole !== 'user') {
    if (!rbac.canChangeAgent) {
      console.log(`ERROR: Current user (${authStore.userRole}) does not allow to change agent`);
      return;
    }

    agentId.value = newAgentId;
    // TODO We just need name
    agentName.value = newAgentId; //`Name for: ${newAgentId}`;
    agentProperties.value = await loadAgentProperties(agentId.value);

    analyzedTimeInterval.value = null;
    analysisResultsAvailable.value = false;

    // We only need to do this once when agent is set
    checkCoachingSuggestions().then(() => {
      console.log(`checkCoachingSuggestions complete`);
    });
    console.log(`Agent name:${agentName.value} agentId:${agentId.value} is set as current`);
  }

  async function loadAgentProperties(agentId) {
    const aDataDoc = await getDoc(doc(fbDb, `tenants/${authStore.tenantId}/agents`, agentId));
    if (aDataDoc.exists()) {
      return Object.assign({}, aDataDoc.data(), { agentId: agentId });
    }
    return null;
  }

  async function analyzeAgentData() {
    analyzing.value = true;
    analysisResultsAvailable.value = false;
    analyzedTimeInterval.value = dateRangeStore.timeInterval;
    clearAgentData();
    await analyzeAgentTraits();
    // TODO REMOVE ! TESTING
    await pause(2000);

    analysisResultsAvailable.value = true;
    analyzing.value = false;
  }

  async function checkCoachingSuggestions() {
    // Check if we need to generate new coaching suggestions
    const ts = Date.now();
    const diff = ts - agentSuggestionsAdded.value;
    if (diff < 24 * 60 * 60 * 1000) {
      console.log(`Coaching suggestions for agent ${agentId.value} were generated ${diff} < 24 hr ago, skipping generation`);
      return;
    }
    generatingCoachingSuggestions.value = true;
    await generateSuggestions(agentId.value, 'P10M');
    //await pause(5000);
    generatingCoachingSuggestions.value = false;
    console.log(`Coaching suggestions generated`);
  }

  const extractTrait = (res, prop) => {
    return (res?.aggregations[prop]?.name?.buckets || []).map((x) => {
      return {
        name: x.key,
        count: x?.doc_count || 0,
        score: x?.score?.value || 0,
        explanation: (x?.explanation?.buckets || []).map((e) => e.key).join(' \n'),
        trendAvgScore: (x?.timeline?.buckets || []).map((e) => e?.scoreStats?.avg || 0),
        trendCount: (x?.timeline?.buckets || []).map((e) => e?.doc_count || 0),
      };
    });
  };

  async function analyzeAgentTraits() {
    if (agentId.value === null) {
      console.log(`agentId must be set first to analyze`);
      return;
    }
    const q = new AnalyticsQuery();

    q.timeInterval.from = dateRangeStore.timeInterval?.from || 'StartOfDay';
    q.timeInterval.to = dateRangeStore.timeInterval?.to || 'now';
    q.timeInterval.interval = dateRangeStore.timeInterval?.interval || '1D'; // '1D'

    q.groupings = [
      { name: 'statsScore', type: 'stats', field: 'score' },
      { name: 'statsProblemsSeverity', type: 'stats', field: 'ProblemsSeverity' },
      { name: 'statsKPIScore', type: 'stats', field: 'KPIScore' },
      { name: 'statsCustomerSentiment', type: 'stats', field: 'CustomerSentiment' },
      {
        name: 'agentWeaknesses',
        type: 'nested',
        path: 'context.analysis.agentWeaknesses',
        groupings: [
          {
            name: 'name',
            type: 'terms',
            field: 'context.analysis.agentWeaknesses.name',
            missing: 'N/A',
            size: 10,
            order: { score: 'desc' },
            groupings: [
              { name: 'score', type: 'sum', field: 'context.analysis.agentWeaknesses.score' },
              { name: 'explanation', type: 'terms', size: 3, field: 'context.analysis.agentWeaknesses.explanation.raw' },
              {
                name: 'timeline',
                type: 'date_histogram',
                interval: '1D', // TODO Refine this
                field: 'context.analysis.agentWeaknesses.datetime',
                extended_bounds: { min: q.timeInterval.from, max: q.timeInterval.from.to },
                groupings: [{ name: 'scoreStats', type: 'stats', field: 'context.analysis.agentWeaknesses.score' }],
              },
            ],
          },
        ],
      },
      {
        name: 'agentStrengths',
        type: 'nested',
        path: 'context.analysis.agentStrengths',
        groupings: [
          {
            name: 'name',
            type: 'terms',
            field: 'context.analysis.agentStrengths.name',
            missing: 'N/A',
            size: 10,
            order: { score: 'desc' },
            groupings: [
              { name: 'score', type: 'sum', field: 'context.analysis.agentStrengths.score' },
              { name: 'explanation', type: 'terms', size: 3, field: 'context.analysis.agentStrengths.explanation.raw' },
              {
                name: 'timeline',
                type: 'date_histogram',
                interval: '1D',
                field: 'context.analysis.agentStrengths.datetime',
                extended_bounds: { min: q.timeInterval.from, max: q.timeInterval.from.to },
                groupings: [{ name: 'scoreStats', type: 'stats', field: 'context.analysis.agentStrengths.score' }],
              },
            ],
          },
        ],
      },
      {
        name: 'agentOpportunities',
        type: 'nested',
        path: 'context.analysis.agentOpportunities',
        groupings: [
          {
            name: 'name',
            type: 'terms',
            field: 'context.analysis.agentOpportunities.name',
            missing: 'N/A',
            size: 10,
            order: { score: 'desc' },
            groupings: [
              { name: 'score', type: 'sum', field: 'context.analysis.agentOpportunities.score' },
              { name: 'explanation', type: 'terms', size: 3, field: 'context.analysis.agentOpportunities.explanation.raw' },
              {
                name: 'timeline',
                type: 'date_histogram',
                interval: '1D',
                field: 'context.analysis.agentOpportunities.datetime',
                extended_bounds: { min: q.timeInterval.from, max: q.timeInterval.from.to },
                groupings: [{ name: 'scoreStats', type: 'stats', field: 'context.analysis.agentOpportunities.score' }],
              },
            ],
          },
        ],
      },
    ];

    q.request = AnalyticsQuery.prepareQuery({
      timeInterval: q.timeInterval,
      groupings: q.groupings,
      indexPrefix: 'conv',
      timestampField: 'datetime',
      filters: [{ type: 'term', field: 'AgentId', value: agentId.value }],
    });

    await analyticsStore.executeAnalyticsQuery(q);

    if (q.success) {
      agentConversations.value = q.result?.total || 0;
      agentAvgConversationScore.value = q.result?.aggregations?.statsScore?.avg || 0;
      agentMinConversationScore.value = q.result?.aggregations?.statsScore?.min || 0;
      agentMaxConversationScore.value = q.result?.aggregations?.statsScore?.max || 0;
      agentAvgSentiment.value = q.result?.aggregations?.statsCustomerSentiment?.avg || 0;
      agentAvgProblemsSeverity.value = q.result?.aggregations?.statsProblemsSeverity?.avg || 0;
      agentAvgKPIScore.value = q.result?.aggregations?.statsKPIScore?.avg || 0;
      agentWeaknesses.value = extractTrait(q.result, 'agentWeaknesses');
      agentStrengths.value = extractTrait(q.result, 'agentStrengths');
      agentOpportunities.value = extractTrait(q.result, 'agentOpportunities');
    } else {
      clearAgentData();
    }
  }

  function clearAgentData() {
    agentConversations.value = 0;
    agentAvgConversationScore.value = 0;
    agentMinConversationScore.value = 0;
    agentMaxConversationScore.value = 0;
    agentAvgSentiment.value = 0;
    agentAvgProblemsSeverity.value = 0;
    agentAvgKPIScore.value = 0;
    agentWeaknesses.value = [];
    agentStrengths.value = [];
    agentOpportunities.value = [];
  }

  async function generateSuggestions(agentId, timeFrom = 'P7D') {
    const functions = getFunctions();
    const generateSuggestionsFunction = httpsCallable(functions, 'generateSuggestions');
    let result = null;
    try {
      result = await generateSuggestionsFunction({
        agentId: agentId,
        timeFrom: timeFrom,
      });
    } catch (e) {
      const msg = `ERROR: Failed to generate suggestions: ${e.message}`;
      console.log(msg);
      toast.error(msg);
      return null;
    }

    if (!result?.data?.success) {
      const msg = `ERROR: Failed to generate suggestions: ${result?.data?.error}`;
      console.log(msg);
      toast.error(msg);
      return result;
    }

    console.log(`Coaching suggestions generated for agent ${agentId} `, result);
    return result;
  }

  return {
    init,
    setAgent,
    analyzeAgentTraits,
    analyzeAgentData,
    agentId,
    agentName,
    analyzing,
    generatingCoachingSuggestions,
    agentConversations,
    agentAvgConversationScore,
    agentMinConversationScore,
    agentMaxConversationScore,
    agentAvgSentiment,
    agentAvgProblemsSeverity,
    agentAvgKPIScore,
    agentWeaknesses,
    agentStrengths,
    agentOpportunities,
    coachingSuggestions,
  };
});
