/*
 * Analytics Query base class
 * This implements analytics requests on ElasticSearch
 * */
import { pathOr } from 'ramda';
import { elasticRequestBuilder } from '@enegelai/core';
import { elasticTransforms } from '@enegelai/core';

const DEFAULT_TIME_INTERVAL = {
  from: 'StartOfDay',
  to: 'now',
  interval: '1H',
};

export class AnalyticsQuery {
  constructor() {
    this.success = false;
    this.error = null;
    this.timeInterval = DEFAULT_TIME_INTERVAL;
    this.groupings = null;
    this.rootPath = null;
    this.rootTitle = null;
    this.request = null;
    this.result = null;
    this.normalized = null;
  }

  setResult(result) {
    this.success = true;
    this.result = result;
  }

  setError(error = null, result = null) {
    this.success = false;
    this.result = result;
    this.error = error;
  }

  normalize() {
    if (!this.success || !this.groupings) {
      return false;
    }
    const aggregations = pathOr(null, ['aggregations'], this.result);
    if (!aggregations) {
      return false;
    }
    this.normalized = elasticTransforms.normalizeEx(aggregations, this.groupings);
    return true;
  }

  toTable(columns) {
    if (!this.normalized) {
      this.normalize();
    }
    //const root = pathOr(null, [0, 'name'], this.groupings);
    //const rootColumnTitle = pathOr(null, [0, 'title'], this.groupings);
    if (!this.normalized || !this.rootPath || !this.rootTitle) {
      return null;
    }
    const t = elasticTransforms.toTable(this.normalized, this.rootPath, this.rootTitle, columns);
    return t;
  }

  rotateTable(table) {
    return elasticTransforms.rotateTable(table);
  }

  // TODO
  static dateHistogramQuery(timeInterval = DEFAULT_TIME_INTERVAL) {
    const q = new AnalyticsQuery();

    q.timeInterval.from = timeInterval?.from || 'StartOfDay';
    q.timeInterval.to = timeInterval?.to || 'now';
    q.timeInterval.interval = timeInterval?.interval || '1H';

    q.groupings = [
      {
        name: 'timeline',
        title: 'Date',
        type: 'date_histogram',
        interval: q.timeInterval.interval,
        extended_bounds: { min: q.timeInterval.from, max: q.timeInterval.to },
        groupings: [
          {
            name: 'problems',
            type: 'nested',
            path: 'context.analysis.problems',
            groupings: [
              {
                name: 'problem',
                type: 'terms',
                field: 'context.analysis.problems.problem',
                missing: 'N/A',
                groupings: [{ name: 'problemseverity', type: 'sum', field: 'context.analysis.problems.importance' }],
              },
              { name: 'sumseverity', type: 'sum', field: 'context.analysis.problems.importance' },
            ],
          },
        ],
      },
    ];

    q.rootPath = ['timeline'];
    q.rootTitle = 'Date';

    q.request = AnalyticsQuery.prepareQuery({
      timeInterval: q.timeInterval,
      groupings: q.groupings,
      indexPrefix: 'conv',
      timestampField: 'datetime',
    });

    return q;
  }

  static groupByQuery(timeInterval = DEFAULT_TIME_INTERVAL) {
    const q = new AnalyticsQuery();

    q.timeInterval.from = timeInterval?.from || 'StartOfDay';
    q.timeInterval.to = timeInterval?.to || 'now';
    q.timeInterval.interval = timeInterval?.interval || '1H';

    q.groupings = [
      {
        name: 'problems',
        type: 'nested',
        path: 'context.analysis.problems',
        groupings: [
          {
            name: 'problem',
            type: 'terms',
            title: 'Problem',
            field: 'context.analysis.problems.problem',
            missing: 'N/A',
            groupings: [{ name: 'problemseverity', type: 'sum', field: 'context.analysis.problems.importance' }],
          },
        ],
      },
    ];

    q.rootPath = ['problems', 'problem'];
    //q.rootPath = ['problems'];
    q.rootTitle = 'Problem';

    q.request = AnalyticsQuery.prepareQuery({
      timeInterval: q.timeInterval,
      groupings: q.groupings,
      indexPrefix: 'conv',
      timestampField: 'datetime',
    });

    return q;
  }

  // Sort: [{field:'name',type:'asc|desc'},...]
  static searchQuery(timeInterval = DEFAULT_TIME_INTERVAL, query = '*', size = 0, offset = 0, sort = null, dateHistogram = false, filters = null) {
    const q = new AnalyticsQuery();

    q.timeInterval.from = timeInterval?.from || 'StartOfDay';
    q.timeInterval.to = timeInterval?.to || 'now';
    q.timeInterval.interval = timeInterval?.interval || '1D'; // '1D'

    if (dateHistogram) {
      q.groupings = [
        {
          name: 'timeline',
          title: 'Date',
          type: 'date_histogram',
          interval: q.timeInterval.interval,
          extended_bounds: { min: q.timeInterval.from, max: q.timeInterval.to },
        },
      ];
      q.rootPath = ['timeline'];
      q.rootTitle = 'Date';
    }

    q.request = AnalyticsQuery.prepareQuery({
      query: query,
      size: size,
      offset: offset,
      timeInterval: q.timeInterval,
      groupings: q.groupings,
      filters: filters,
      sort: sort,
      indexPrefix: 'conv',
      timestampField: 'datetime',
    });

    return q;
  }

  static searchConversationQuery(conversationId) {
    const q = new AnalyticsQuery();

    // Search during past year
    q.timeInterval.from = 'P12M';
    q.timeInterval.to = 'now';
    q.timeInterval.interval = '1M';

    q.request = AnalyticsQuery.prepareQuery({
      query: '*',
      size: 1,
      offset: 0,
      timeInterval: q.timeInterval,
      filters: [{ type: 'term', field: 'id', value: conversationId }],
      indexPrefix: 'conv',
      timestampField: 'datetime',
    });

    return q;
  }

  static prepareQuery({ query = '*', size = 0, offset = 0, timeInterval = DEFAULT_TIME_INTERVAL, groupings, filters = null, sort = null, indexPrefix, timestampField }) {
    console.log(`timeInterval: ${JSON.stringify(timeInterval)}`);

    let aggs = elasticRequestBuilder.groupingToAgg({
      from: timeInterval.from,
      to: timeInterval.to,
      interval: timeInterval.interval,
      groupings: groupings,
    });

    let reqOptions = {
      query: query, // TODO Consider applying query to dashboards as well
      from: timeInterval.from,
      to: timeInterval.to,
      interval: timeInterval.interval,
      size: size,
      offset: offset,
      aggregations: aggs,
      sort: sort,
      indexPrefix: indexPrefix, // 'incident-*',   // TODO TEMP - Optimize
      timestampField: timestampField, // 'triggered'  // TODO TEMP - Optimize
    };

    if (filters) {
      reqOptions.filters = filters;
    }

    let request = elasticRequestBuilder.buildRequest(reqOptions);
    return request;
  }
}
